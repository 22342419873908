import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faStar, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from "react-bootstrap";
import Section from "component/common/Section";
import SectionHeader from './SectionHeader';
import { Trans, useTranslation } from "react-i18next";

// import '/frontend/src/styles/sass/main.scss'; // Make sure this is correct

function Features() {
  const { t } = useTranslation();

  return (
    <Section bg="light" className="text-center">
      <SectionHeader
        title={t('features_section_title')}
        subtitle={t('features_section_subtitle')}
      />
      <Row className="mt-6">
        <Col
          lg={4}
        >
          <Card className="card-span h-100">
            <div className="card-span-img">
              <FontAwesomeIcon icon={faBullseye} className="fs-4 text-info" />
            </div>
            <Card.Body className="pt-6 pb-4">
              <h5 className="mb-2"><Trans>feature_1_title</Trans></h5>
              <p><Trans>feature_1_description</Trans></p>
            </Card.Body>
          </Card>
        </Col>
        <Col
          lg={4}
          className="mt-6 mt-lg-0"
        >
          <Card className="card-span h-100">
            <div className="card-span-img">
              <FontAwesomeIcon icon={faStar} className="fs-4 text-success" />
            </div>
            <Card.Body className="pt-6 pb-4">
              <h5 className="mb-2"><Trans>feature_2_title</Trans></h5>
              <p><Trans>feature_2_description</Trans></p>
            </Card.Body>
          </Card>
        </Col>
        <Col
          lg={4}
          className="mt-6 mt-lg-0"
        >
          <Card className="card-span h-100">
            <div className="card-span-img">
              <FontAwesomeIcon icon={faInfinity} className="fs-4 text-danger" />
            </div>
            <Card.Body className="pt-6 pb-4">
              <h5 className="mb-2"><Trans>feature_3_title</Trans></h5>
              <p><Trans>feature_3_description</Trans></p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
}

export default Features;