import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import Select, { components } from "react-select";
import AdvanceTableWrapper from "component/common/advance-table/AdvanceTableWrapper";
import AdvanceTablePagination from "component/common/advance-table/AdvanceTablePagination";
import AdvanceTable from "component/common/advance-table/AdvanceTable";
import ZymranIcon from "component/common/ZymranIcon";
import useLocale from "hooks/useLocale";
import moment from "moment-timezone";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { getLocations } from "service/location/LocationService";
import SalaryStaffProcessing from "./SalaryStaffProcessing";
import { getSalaryAll } from "service/salary/SalaryService";
import { ReactComponent as EmptyListIcon } from "assets/img/icons/empty-list.svg";

const SalaryStaffView = () => {
  const timezone = moment.tz.guess();
  const { t } = useTranslation();
  const locale = useLocale();

  const [showSalaryProcessingModal, setShowSalaryProcessingModal] =
    useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationOption, setSelectedLocationOption] = useState({
    value: "",
    label: "",
  });
  const [salaryData, setSalaryData] = useState([]);
  const columns = [
    {
      accessor: "paymentDate",
      Header: t("payment_date"),
      headerProps: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
        },
      }),
      Cell: (rowData) => {
        const { dueDate } = rowData.row.original;
        const zonedTime = toZonedTime(dueDate, timezone);
        return <>{format(zonedTime, "dd MMM yyyy", { locale })}</>;
      },
    },
    {
      accessor: "name",
      Header: t("staff_member"),
      Cell: (rowData) => {
        const { staff } = rowData.row.original;
        return (
          <>
            {staff.firstName} {staff.lastName}
          </>
        );
      },
    },
    {
      accessor: "position",
      Header: t("position"),
      Cell: (rowData) => {
        const { staff } = rowData.row.original;
        return <>{staff.position}</>;
      },
    },
    {
      accessor: "location",
      Header: t("location"),
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <>{location.name}</>;
      },
    },
    {
      accessor: "period",
      Header: t("payment_period"),
      headerProps: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
        },
      }),
      Cell: (rowData) => {
        const { calculationPeriod } = rowData.row.original;
        const calculationStartDate = toZonedTime(calculationPeriod.startDate, timezone);
        const calculationEndDate = toZonedTime(calculationPeriod.endDate, timezone);
        return (
          <>
            {format(calculationStartDate, "dd MMM yyyy", { locale })} -
            <br />
            {format(calculationEndDate, "dd MMM yyyy", { locale })}
          </>
        );
      },
    },
    {
      accessor: "debt",
      Header: t("debt") + ", ₸",
      Cell: (rowData) => {
        const { debt } = rowData.row.original;
        return <>{debt}</>;
      },
    },
    {
      accessor: "sumToPay",
      Header: t("sum_to_pay_out_short") + ", ₸",
      Cell: (rowData) => {
        const { sumToPay } = rowData.row.original;
        return <>{sumToPay}</>;
      },
    },
    {
      accessor: "none",
      Header: t(""),
      disableSortBy: true,
      Cell: (rowData) => {
        const { staff } = rowData.row.original;
        return (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              setSelectedStaff(staff);
              setShowSalaryProcessingModal(true);
            }}
          >
            <Trans>pay_salary</Trans>
          </Button>
        );
      },
    },
  ];

  const [salaryPaid, setSalaryPaid] = useState(false);

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedLocationOption && selectedLocationOption.value !== "") {
      loadSalaryData();
    }
    // eslint-disable-next-line
  }, [selectedLocationOption, salaryPaid]);

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      const locs = [];
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + ", " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setLocationOptions(locs);
      setSelectedLocationOption(locs[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSalaryData = async () => {
    try {
      const today = new Date();
      const startDate = today.toISOString().split("T")[0] + "T00:00:00.000Z";
      const endDate = startDate;
      const payload = {
        locationId: selectedLocationOption.value,
        startDate: startDate,
        endDate: endDate,
      };
      const response = await getSalaryAll(payload);
      setSalaryData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSalaryProcessingModalClose = () => {
    setShowSalaryProcessingModal(false);
    if (salaryPaid) {
      setSalaryPaid(false);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ZymranIcon name="chevron-down" className="secondary-text-color" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>salary_calculation</Trans>
        </h1>
      </div>

      {salaryData && salaryData.length > 0 ? (
        <AdvanceTableWrapper
          columns={columns}
          data={salaryData}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <div className="finance-filter-area">
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span className="p2">
                      <Trans>location</Trans>:
                    </span>
                    <Select
                      id="location"
                      name="location"
                      classNamePrefix="react-select"
                      className="react-select--sm finance-select"
                      placeholder="test"
                      value={selectedLocationOption}
                      options={locationOptions}
                      required
                      onChange={(option) => setSelectedLocationOption(option)}
                      components={{ DropdownIndicator }}
                    />
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-2">
              <div className="table-rounded-wrapper">
                <AdvanceTable
                  table
                  headerClassName="p2-bold secondary-text-color text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: "sm",
                    className: "p2 mb-0 overflow-hidden jazu-list-table",
                  }}
                />
              </div>
            </Card.Body>
            <Card.Footer className="pt-0">
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card className="mb-3">
          <Card.Body>
            <div className="d-flex flex-column justify-content-center align-items-center gap-4 py-3 empty-services">
              <EmptyListIcon />
              <p className="p2 mb-0">
                <Trans>no_salary_data_available</Trans>
              </p>
            </div>
          </Card.Body>
        </Card>
      )}

      <Modal
        show={showSalaryProcessingModal}
        onHide={handleSalaryProcessingModalClose}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title>
            <Trans>income_detail</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalaryStaffProcessing
            handleClose={handleSalaryProcessingModalClose}
            selectedStaff={selectedStaff}
            selectedLocation={selectedLocationOption}
            setSalaryPaid={setSalaryPaid}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SalaryStaffView;
