import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { updateService } from "../../../service/service-entity/ServiceEntityService";
import { getServiceCategories } from "../../../service/service-category/ServiceCategoryService";
import { getStaffLocations } from "../../../service/staff-location/StaffLocationService";
import { getService } from "../../../service/service-entity/ServiceEntityService";
import { getLocations } from "../../../service/location/LocationService";
import { Form, Button, Col, Card, Row } from "react-bootstrap";
import BlockUI from "../../../util/block-ui/block-ui";
import { notifyError, notifySuccess } from "../../../util/notify";
import CreatableSelect from "react-select/creatable";
import EnhancedSelect from "component/common/EnhancedSelect";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import ZymranIcon from "component/common/ZymranIcon";
import { getSystemServices } from "service/system-service/SystemServiceEntityService";
import ServiceLocationSection from "component/service/ServiceLocationSection";
import { isNumericInput } from "util/validation";
import { not } from "is_js";

const EditService = ({
  serviceId,
  handleClose,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const [serviceData, setServiceData] = useState({
    name: "",
    serviceCategoryId: "",
    serviceCategory: {
      value: 0,
      label: "",
    },
    availableFor: "",
    description: "",

    priceData: {
      duration: { label: "15 ".concat(t("minute")), value: 15 },
      priceType: { label: t("fixed"), value: "fixed" },
      price: 0,
    },
    staffLocationServices: [],
  });

  const [selectedLocationOption, setSelectedLocationOption] = useState({
    value: "",
    label: "",
  });
  const [locationStaffPriceData, setLocationStaffPriceData] = useState(
    new Map()
  );
  const [locationPriceData, setLocationPriceData] = useState(new Map());
  const [locationSections, setLocationSections] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const durationTimeLabel = [
    { label: "15 ".concat(t("minute")), value: 15 },
    { label: "30 ".concat(t("minute")), value: 30 },
    { label: "45 ".concat(t("minute")), value: 45 },
    { label: "1 ".concat(t("hour")), value: 60 },
    { label: "1".concat(t("h")).concat(" 15").concat(t("minute")), value: 75 },
    { label: "1".concat(t("h")).concat(" 30").concat(t("minute")), value: 90 },
    { label: "1".concat(t("h")).concat(" 45").concat(t("minute")), value: 105 },
    { label: "2".concat(t("h")), value: 120 },
    { label: "3".concat(t("h")), value: 180 },
    { label: "4".concat(t("h")), value: 240 },
    { label: "5".concat(t("h")), value: 300 },
    { label: "6".concat(t("h")), value: 360 },
    { label: "7".concat(t("h")), value: 420 },
    { label: "8".concat(t("h")), value: 480 },
  ];
  const durationTimeLabelMap = new Map(
    durationTimeLabel.map((obj) => {
      return [obj.value, obj.label];
    })
  );
  const [errors, setErrors] = useState({
    basicInformation: {
      name: null,
      selectedServiceCategory: null,
      availableFor: null,
      description: null,
    },
    locationPrice: {},
    locationStaffPrice: {},
  });
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [selectedAvailableFor, setSelectedAvailableFor] = useState(null);
  const [allStaffLocations, setAllStaffLocations] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [genders, setGenders] = useState([]);

  const [locationOptions, setLocationOptions] = useState([]);
  const availableLocationOptions = locationOptions.filter(
    (option) => !selectedLocations.includes(option.value)
  );

  const [durationTimes, setDurationTimes] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);
  const [loadingRelatedData, setLoadingRelatedData] = useState(true);
  const [loadingServiceData, setLoadingServiceData] = useState(true);
  const [systemServicesOptions, setSystemServicesOptions] = useState([]);
  const [totalSelectedStaffLocations, setTotalSelectedStaffLocations] =
    useState([]);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        loadServiceCategories(),
        initGenders(),
        loadLocations(),
        loadAllStaffLocations(),
        initDurationTimes(),
        initPriceTypes(),
        loadSystemServices(),
      ]);

      setLoadingRelatedData(false);
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (serviceCategories.length > 0) {
      loadServiceData();
    }
    // eslint-disable-next-line
  }, [serviceCategories.length]);

  useEffect(() => {
    const defaultPriceData = serviceData.priceData;

    if (locationPriceData.size > 0 && locationStaffPriceData.size > 0) {
      let temp = new Map();

      locationPriceData.forEach((value, key) => {
        let priceData = { ...value };

        if (!priceData.toSave && !priceData.fromDb) {
          priceData["duration"] = defaultPriceData.duration;
          priceData["priceType"] = defaultPriceData.priceType;
          priceData["price"] = defaultPriceData.price;
        }
        temp.set(key, priceData);
      });
      setLocationPriceData(temp);

      temp = new Map();

      locationStaffPriceData.forEach((value, key) => {
        let temp1 = new Map();

        value.forEach((value1, key1) => {
          let priceData = { ...value1 };
          if (!locationPriceData.get(key).toSave) {
            if (!value1.toSave && !value1.fromDb) {
              priceData["duration"] = defaultPriceData.duration;
              priceData["priceType"] = defaultPriceData.priceType;
              priceData["price"] = defaultPriceData.price;
            }
          }
          temp1.set(key1, priceData);
        });
        temp.set(key, temp1);
      });
      setLocationStaffPriceData(temp);
    }
    // eslint-disable-next-line
  }, [serviceData.priceData, locationStaffPriceData.size]);

  useEffect(() => {
    if (serviceData.name !== "" && allStaffLocations.length > 0) {
      initLocationPriceData();
    }
    // eslint-disable-next-line
  }, [serviceData.name, allStaffLocations.length]);

  useEffect(() => {
    if (locationOptions.length > 0 && selectedLocationOption.value !== "") {
      initLocationPriceData();
    }
    // eslint-disable-next-line
  }, [locationOptions.length, selectedLocationOption.value]);

  useEffect(() => {
    if (selectedLocationOption.value !== "") {
      initNewlyAddedLocationStaffPriceData();
    }
    // eslint-disable-next-line
  }, [totalSelectedStaffLocations.length, totalSelectedStaffLocations]);

  useEffect(() => {
    if (serviceData.name !== "" && locationPriceData.size > 0) {
      initLocationStaffPriceData();
    }
    // eslint-disable-next-line
  }, [serviceData.name, locationPriceData]);

  const initDurationTimes = () => {
    const durationTimes = [
      { label: "15 ".concat(t("minute")), value: 15 },
      { label: "30 ".concat(t("minute")), value: 30 },
      { label: "45 ".concat(t("minute")), value: 45 },
      { label: "1 ".concat(t("hour")), value: 60 },
      {
        label: "1".concat(t("h")).concat(" 15").concat(t("minute")),
        value: 75,
      },
      {
        label: "1".concat(t("h")).concat(" 30").concat(t("minute")),
        value: 90,
      },
      {
        label: "1".concat(t("h")).concat(" 45").concat(t("minute")),
        value: 105,
      },
      { label: "2".concat(t("h")), value: 120 },
      { label: "3".concat(t("h")), value: 180 },
      { label: "4".concat(t("h")), value: 240 },
      { label: "5".concat(t("h")), value: 300 },
      { label: "6".concat(t("h")), value: 360 },
      { label: "7".concat(t("h")), value: 420 },
      { label: "8".concat(t("h")), value: 480 },
    ];
    setDurationTimes(durationTimes);
  };

  const initPriceTypes = () => {
    const priceTypes = [
      { label: t("fixed"), value: "fixed" },
      { label: t("from"), value: "from" },
      { label: t("free"), value: "free" },
    ];
    setPriceTypes(priceTypes);
  };

  const getDurationTimeLabel = (duration) => {
    return durationTimeLabelMap.get(duration);
  };

  const loadServiceData = async () => {
    try {
      const response = await getService(serviceId);
      const sd = response.data;

      const priceData = {
        duration: {
          value: sd.duration,
          label: getDurationTimeLabel(sd.duration),
        },
        priceType: {
          value: sd.priceType,
          label: t(sd.priceType),
        },
        price: sd.price,
      };

      let result = {
        id: sd.id,
        name: sd.name,
        serviceCategoryId: sd.serviceCategoryId,
        availableFor: sd.availableFor,
        description: sd.description,
        priceData: priceData,
        locationPrices: sd.locationPrices,
        staffLocationServices: sd.staffLocationServices,
      };

      const serviceCategory = serviceCategories.find((category) => {
        return category.value === result.serviceCategoryId;
      });

      setSelectedServiceCategory(serviceCategory);

      setSelectedServiceName({
        value: result.id,
        label: result.name,
      });

      let totalSelectedStaffLocations = [];
      result.staffLocationServices.forEach((sls) => {
        totalSelectedStaffLocations.push(sls.staffLocation);
      });
      setTotalSelectedStaffLocations(totalSelectedStaffLocations);

      const availableFor = genders.find((gender) => {
        return gender.value === result.availableFor;
      });

      setSelectedAvailableFor(availableFor);
      setServiceData(result);

      let initialLocationSections = [];

      if (result.locationPrices.length > 0) {
        initialLocationSections = result.locationPrices.map(
          (price) => price.location.id
        );
      } else if (result.staffLocationServices.length > 0) {
        initialLocationSections = result.staffLocationServices.map(
          (sls) => sls.staffLocation.location.id
        );
      }
      setLocationSections(initialLocationSections);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServiceData(false);
    }
  };

  const initLocationPriceData = () => {
    let locationPriceData = new Map();
    const isServiceDataAvailable =
      serviceData.name !== "" && allStaffLocations.length > 0;

    locationOptions.forEach((location) => {
      let priceData;

      if (isServiceDataAvailable) {
        const existingPriceData = serviceData.locationPrices.find(
          (lp) => lp.location.id === location.value
        );

        if (existingPriceData) {
          priceData = {
            duration: {
              value: existingPriceData.duration,
              label: getDurationTimeLabel(existingPriceData.duration),
            },
            priceType: {
              value: existingPriceData.priceType,
              label: t(existingPriceData.priceType),
            },
            price: existingPriceData.price,
            toSave: false,
            fromDb: true,
          };
        } else {
          priceData = {
            duration: serviceData.priceData.duration,
            priceType: serviceData.priceData.priceType,
            price: serviceData.priceData.price,
            toSave: false,
            fromDb: false,
          };
        }
      } else {
        priceData = {
          duration: { label: "15 ".concat(t("minute")), value: 15 },
          priceType: { label: t("fixed"), value: "fixed" },
          price: 0,
          toSave: false,
        };
      }

      locationPriceData.set(location.value, priceData);
    });

    setLocationPriceData(locationPriceData);
  };

  // TODO: This method needs to be refactored
  const initLocationStaffPriceData = () => {
    const locationStaffPriceData = new Map();

    locationOptions.forEach((location) => {
      locationStaffPriceData.set(location.value, new Map());
    });

    totalSelectedStaffLocations.forEach((sl) => {
      const staffPriceData = locationStaffPriceData.get(sl.location.id);
      const locPriceData = locationPriceData.get(sl.location.id);
      staffPriceData.set(sl.id, {
        duration: locPriceData.duration,
        priceType: locPriceData.priceType,
        price: locPriceData.price,
        toSave: false,
        fromDb: false,
      });

      locationStaffPriceData.set(sl.location.id, staffPriceData);
    });

    const staffLocationServices = serviceData.staffLocationServices;

    staffLocationServices.forEach((sls) => {
      const location = sls.staffLocation.location;
      const staffPriceData = locationStaffPriceData.get(location.id);

      const servicePrice = sls.servicePrice;
      const locationPricesData = serviceData.locationPrices.find(
        (lp) => lp.location.id === location.id
      );

      const priceData = {
        duration: {
          value: servicePrice
            ? servicePrice.duration
            : locationPricesData?.duration ?? 15,
          label: servicePrice
            ? getDurationTimeLabel(servicePrice.duration)
            : locationPricesData
              ? getDurationTimeLabel(locationPricesData.duration)
              : "15 ".concat(t("minute")),
        },
        priceType: {
          value: servicePrice
            ? servicePrice.priceType
            : locationPricesData?.priceType ?? "fixed",
          label: servicePrice
            ? t(servicePrice.priceType)
            : locationPricesData
              ? t(locationPricesData.priceType)
              : t("fixed"),
        },
        price: servicePrice
          ? servicePrice.price
          : locationPricesData?.price ?? 0,
        toSave: false,
        fromDb: true,
      };
      staffPriceData.set(sls.staffLocation.id, priceData);
      locationStaffPriceData.set(sls.staffLocation.location.id, staffPriceData);
    });
    setLocationStaffPriceData(locationStaffPriceData);
  };

  const initGenders = () => {
    const genders = [
      { label: t("male"), value: 1 },
      { label: t("female"), value: 2 },
      { label: t("all"), value: 3 },
    ];
    setGenders(genders);
  };

  const initNewlyAddedLocationStaffPriceData = () => {
    const newLocationStaffPriceData = new Map(locationStaffPriceData);

    const allSelectedStaffLocationIds = totalSelectedStaffLocations.map(
      (sl) => sl.id
    );

    // Group totalSelectedStaffLocations by locationId
    const groupedSelectedStaffLocations = totalSelectedStaffLocations.reduce(
      (acc, sl) => {
        if (!acc[sl.location.id]) {
          acc[sl.location.id] = [];
        }
        acc[sl.location.id].push(sl);
        return acc;
      },
      {}
    );

    Object.keys(groupedSelectedStaffLocations).forEach((locationId) => {
      const numericLocationId = locationId;
      const staffPriceData = new Map(
        newLocationStaffPriceData.get(numericLocationId)
      );

      for (let [staffLocationId] of staffPriceData.entries()) {
        if (!allSelectedStaffLocationIds.includes(staffLocationId)) {
          staffPriceData.delete(staffLocationId);
        }
      }

      groupedSelectedStaffLocations[locationId].forEach((sl) => {
        const staffLocationId = sl.id;

        const currentLocationPriceData =
          locationPriceData.get(numericLocationId);
        const durationValue = currentLocationPriceData
          ? currentLocationPriceData.duration.value
          : 15;
        const durationLabel = `${durationValue} ${t(
          durationValue === 1 ? "minute" : "minutes"
        )}`;
        const priceTypeValue = currentLocationPriceData
          ? currentLocationPriceData.priceType.value
          : "fixed";
        const priceValue = currentLocationPriceData
          ? currentLocationPriceData.price
          : 0;

        // Check if the staffLocationId already exists in the staffPriceData map
        if (!staffPriceData.has(staffLocationId)) {
          const priceData = {
            duration: {
              label: durationLabel,
              value: durationValue,
            },
            priceType: {
              label: t(priceTypeValue),
              value: priceTypeValue,
            },
            price: priceValue,
            toSave: false,
          };
          staffPriceData.set(staffLocationId, priceData);
        }
      });

      newLocationStaffPriceData.set(numericLocationId, staffPriceData);
    });

    setLocationStaffPriceData(newLocationStaffPriceData);
  };

  const setLocationField = (locationId, field, value) => {
    let priceData = locationPriceData.get(locationId);

    if (field === "price" && value !== "" && !isNumericInput(value)) {
      return;
    }

    priceData[field] = value;
    priceData["toSave"] = true;

    // Update locationStaffPriceData
    const staffPrice = locationStaffPriceData.get(locationId);

    const currentSelectedStaffLocations = totalSelectedStaffLocations.filter(
      (sl) => sl.location.id === locationId
    );
    currentSelectedStaffLocations.forEach((sl) => {
      let priceData = staffPrice.get(sl.id);
      if (!priceData.toSave && !priceData.fromDb) {
        priceData[field] = value;
      }
      staffPrice.set(sl.id, priceData);
    });
    // setLocationPriceData(new Map(locationPriceData.set(selectedLocationOption.value, priceData)));
    setLocationStaffPriceData(
      new Map(locationStaffPriceData.set(locationId, staffPrice))
    );
  };

  const loadServiceCategories = async () => {
    try {
      const scList = [];
      const response = await getServiceCategories();
      response.data.forEach((sc) => {
        scList.push({ value: sc.id, label: sc.name });
      });
      setServiceCategories(scList);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const loadAllStaffLocations = async () => {
    try {
      const response = await getStaffLocations();
      setAllStaffLocations(response.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const loadLocations = async () => {
    try {
      const response = await getLocations();
      const locs = [];
      response.data.forEach((loc) => {
        const location = {
          value: loc.id,
          label:
            loc.name + " " + loc.address.street + " " + loc.address.houseNumber,
        };
        locs.push(location);
      });
      setSelectedLocationOption(locs[0]);
      setLocationOptions(locs);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const loadSystemServices = async () => {
    try {
      const response = await getSystemServices();
      const sysServices = [];

      response.data.forEach((sysService) => {
        const service = {
          value: sysService.id,
          label: sysService.name,
        };
        sysServices.push(service);
      });

      setSystemServicesOptions(sysServices);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const addLocationSection = () => {
    //location id is used as a unique id for sections and it is completely decoupled from the locationOptions
    const nextLocationValue = locationOptions.find(
      (loc) => !locationSections.includes(loc.value)
    );
    if (nextLocationValue) {
      setLocationSections((prevSections) => [
        ...prevSections,
        nextLocationValue.value,
      ]);
    }
  };

  const removeLocationSection = (sectionValue, locationValue) => {
    setLocationSections((prevSections) =>
      prevSections.filter((value) => value !== sectionValue)
    );

    // Update the toSave flag in locationPriceData
    setLocationPriceData((prevData) => {
      const updatedData = new Map(prevData);
      const data = updatedData.get(locationValue);
      if (data) {
        data.toSave = false;
        updatedData.set(locationValue, data);
      }
      return updatedData;
    });
    setSelectedLocations((prevLocations) =>
      prevLocations.filter((loc) => loc !== locationValue)
    );
    setTotalSelectedStaffLocations((prevStaff) =>
      prevStaff.filter((sl) => sl.location.id !== locationValue)
    );
  };

  const setField = (field, value) => {
    setServiceData({
      ...serviceData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleServiceCategoryChange = (selectedOption) => {
    setSelectedServiceCategory(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (
      errors.basicInformation &&
      errors.basicInformation.selectedServiceCategory
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        basicInformation: {
          ...prevErrors.basicInformation,
          selectedServiceCategory: null,
        },
      }));
    }
  };

  const handleAvailableForChange = (selectedOption) => {
    setSelectedAvailableFor(selectedOption);
    // Check and see if errors exist, and remove them from the error object:
    if (errors.basicInformation && errors.basicInformation.availableFor) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        basicInformation: {
          ...prevErrors.basicInformation,
          availableFor: null,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = findFormErrors();

    const hasBasicInfoErrors =
      Object.keys(newErrors.basicInformation).length > 0;

    // Check if there are any locationPrice errors:
    const hasLocationPriceErrors = Object.values(newErrors.locationPrice).some(
      (location) => {
        return location && Object.keys(location).length > 0;
      }
    );

    // Check if there are any locationStaffPrice errors:
    const hasLocationStaffPriceErrors = Object.values(
      newErrors.locationStaffPrice
    ).some((location) => {
      return (
        location &&
        Object.values(location).some((staffLocation) => {
          return staffLocation && Object.keys(staffLocation).length > 0;
        })
      );
    });
    if (
      hasBasicInfoErrors ||
      hasLocationPriceErrors ||
      hasLocationStaffPriceErrors
    ) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const payload = { ...serviceData };
      const staffLocations = [];

      totalSelectedStaffLocations.forEach((staff) => {
        staffLocations.push(staff.id);
      });

      payload.staffLocations = staffLocations;

      payload.duration = payload.priceData.duration.value;
      payload.priceType = payload.priceData.priceType.value;
      payload.price = payload.priceData.price;

      const locationStaffPrice = {};
      const locationPrice = {};

      locationStaffPriceData.forEach((staffPrice, locationId) => {
        const newStaffPrice = {};
        staffPrice.forEach((priceData, slId) => {
          const newPriceData = {
            duration: priceData.duration.value,
            priceType: priceData.priceType.value,
            price: Number(priceData.price),
          };
          newStaffPrice[slId] = newPriceData;
        });
        locationStaffPrice[locationId] = newStaffPrice;
      });

      locationPriceData.forEach((priceData, locationId) => {
        if (
          priceData.price !== null &&
          priceData.price !== undefined &&
          priceData.price !== ""
        ) {
          const newPriceData = {
            duration: priceData.duration.value,
            priceType: priceData.priceType.value,
            price: Number(priceData.price),
          };
          locationPrice[locationId] = newPriceData;
        }
      });

      payload.locationStaffPrice = locationStaffPrice;
      payload.locationPrice = locationPrice;
      payload.price = Number(payload.price);

      delete payload.priceData;
      delete payload.locationPrices;
      delete payload.staffLocationServices;

      try {
        setBlocking(true);
        const response = await updateService(payload);
        notifySuccess(t("service_updated"));
        const service = response.data;
        onUpdate(service);
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setBlocking(false);
        // setServiceUpdated(true);
      }
    }
  };

  const findFormErrors = () => {
    const { name, description } = serviceData;

    const newErrors = {
      basicInformation: {},
      locationPrice: {},
      locationStaffPrice: {},
    };

    if (!name || name === "")
      newErrors.basicInformation.name = t("field_required");

    if (!selectedServiceCategory || !selectedServiceCategory.value)
      newErrors.basicInformation.selectedServiceCategory = t("field_required");

    if (!selectedAvailableFor || !selectedAvailableFor.value)
      newErrors.basicInformation.availableFor = t("field_required");

    if (description.length > 1024)
      newErrors.basicInformation.description = t("too_long");

    let hasLocationPriceDataToSave = false;

    locationPriceData.forEach((data, locationId) => {
      if (data.toSave) {
        hasLocationPriceDataToSave = true;
        if (!data.duration) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].duration = t("field_required");
        }
        if (!data.priceType) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].priceType = t("field_required");
        }
        if (data.priceType !== "free" && data.price <= 0) {
          if (!newErrors.locationPrice[locationId])
            newErrors.locationPrice[locationId] = {};
          newErrors.locationPrice[locationId].price = t(
            "price_should_be_greater_than_zero"
          );
        }
      }
    });

    let hasLocationStaffPriceData = false;

    // Location staff price validation
    locationStaffPriceData.forEach((staffPrice, locationId) => {
      staffPrice.forEach((data, staffLocationId) => {
        hasLocationStaffPriceData = true;

        if (!data.duration) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].duration =
            t("field_required");
        }
        if (!data.priceType) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].priceType =
            t("field_required");
        }
        if (data.priceType !== "free" && data.price <= 0) {
          if (!newErrors.locationStaffPrice[locationId])
            newErrors.locationStaffPrice[locationId] = {};
          if (!newErrors.locationStaffPrice[locationId][staffLocationId])
            newErrors.locationStaffPrice[locationId][staffLocationId] = {};
          newErrors.locationStaffPrice[locationId][staffLocationId].price = t(
            "price_should_be_greater_than_zero"
          );
        }
      });
    });

    if (!hasLocationPriceDataToSave && !hasLocationStaffPriceData) {
      if (locationOptions.length > 0) {
        newErrors.locationPrice.general = t("no_location_price_data");
      } else {
        newErrors.locationPrice.general = t("locations_not_added");
      }
    }
    return newErrors;
  };

  const handleSelectedLocationAddition = (locationId) => {
    if (!selectedLocations.includes(locationId)) {
      setSelectedLocations((prevSelectedLocations) => [
        ...prevSelectedLocations,
        locationId,
      ]);
    }
  };

  const handleCreateOption = (inputValue) => {
    setField("name", inputValue);
    setSelectedServiceName({ value: null, label: inputValue });
  };

  const handleCrateableSelectChange = (newValue) => {
    if (newValue) {
      setField("name", newValue.label);
      setSelectedServiceName(newValue);
    } else {
      setField("name", "");
      setSelectedServiceName(null);
    }
  };

  const formatCreateLabel = () => (
    <div className="format-create-label">
      <ZymranIcon name="plus" /> <Trans>select_as_new_service</Trans>
    </div>
  );

  if (loadingRelatedData || loadingServiceData) {
    return (
      <div className="container central-content-container">Loading...</div>
    );
  }

  return (
    <div className="container central-content-container">
      <Form>
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>basic_information</Trans>
            </h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-3">
            <Row className="g-3">
              <Col md={5}>
                <CreatableSelect
                  isClearable
                  options={systemServicesOptions}
                  onChange={(newValue) => handleCrateableSelectChange(newValue)}
                  onCreateOption={handleCreateOption}
                  value={selectedServiceName}
                  placeholder={t("select_service_name_or_type_new_one")}
                  className="custom-createable-select"
                  formatCreateLabel={formatCreateLabel}
                />
              </Col>
              <Col md={4}>
                <EnhancedSelect
                  label={t("service_category")}
                  placeholder={t("select_service_category")}
                  selectedValue={selectedServiceCategory}
                  options={serviceCategories}
                  onChange={(option) => handleServiceCategoryChange(option)}
                  errors={errors.basicInformation.selectedServiceCategory}
                  groupClassName="flex-grow-1"
                />
              </Col>
              <Col md={3}>
                <EnhancedSelect
                  label={t("available_for")}
                  placeholder={t("available_for")}
                  selectedValue={selectedAvailableFor}
                  options={genders}
                  onChange={(option) => handleAvailableForChange(option)}
                  errors={errors.basicInformation.availableFor}
                  groupClassName="flex-grow-1"
                />
              </Col>
            </Row>
            <EnhancedFormControl
              id="description"
              name="description"
              type="text"
              label={t("description")}
              placeholder={t("description")}
              value={serviceData.description}
              onChange={(e) => setField("description", e.target.value)}
              errors={errors.basicInformation.description}
              isInvalid={!!errors.basicInformation.description}
              description={t("service_description_instruction")}
            />
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header className="plain-card-header">
            <h3 className="mb-0">
              <Trans>locations_and_staff</Trans>
            </h3>
            {errors.locationPrice.general && (
              <div className="invalid-feedback p-0">
                {errors.locationPrice.general}
              </div>
            )}
          </Card.Header>
          <Card.Body className="d-flex flex-column p-0">
            {locationSections.map((sectionValue) => (
              <ServiceLocationSection
                key={sectionValue}
                sectionValue={sectionValue}
                preselectedLocationId={sectionValue}
                locationOptions={availableLocationOptions}
                durationOptions={durationTimes}
                priceTypeOptions={priceTypes}
                locationPriceData={locationPriceData}
                locationStaffPriceData={locationStaffPriceData}
                setLocationPriceData={setLocationPriceData}
                setLocationStaffPriceData={setLocationStaffPriceData}
                errors={errors}
                setErrors={setErrors}
                locationSections={locationSections}
                removeLocationSection={removeLocationSection}
                setSelectedLocations={setSelectedLocations}
                totalSelectedStaffLocations={totalSelectedStaffLocations}
                setTotalSelectedStaffLocations={setTotalSelectedStaffLocations}
                handleSelectedLocationAddition={handleSelectedLocationAddition}
                setLocationField={setLocationField}
              />
            ))}
            <div className="service-location-section-remove-button-area">
              <Button
                variant="blue-light"
                size="sm"
                onClick={addLocationSection}
                disabled={locationSections.length === locationOptions.length}
              >
                <ZymranIcon name="plus" />
                <Trans>add_location</Trans>
              </Button>
            </div>
          </Card.Body>
        </Card>
        <BlockUI tag="div" blocking={blocking} />
        <div className="d-flex justify-content-end align-items-start gap-3">
          <Button variant="grey" onClick={handleClose}>
            <Trans>cancel</Trans>
          </Button>
          <Button type="submit" variant="blue" onClick={handleSubmit}>
            <Trans>save_service</Trans>
          </Button>
        </div>
      </Form>
    </div>
  );
};

EditService.propTypes = {
  serviceId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditService;
