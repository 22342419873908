import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { getServiceCategories } from "../../service/service-category/ServiceCategoryService";
import {
  getServices,
  deleteService,
} from "../../service/service-entity/ServiceEntityService";
import { deleteServiceCategory } from "../../service/service-category/ServiceCategoryService";
import { notifySuccess, notifyWarning } from "../../util/notify";
import CreateService from "./create/CreateService";
import EditService from "./edit/EditService";
import CreateCategory from "./category/CreateCategory";
import EditCategory from "./category/EditCategory";
import classNames from "classnames";
import ListGroup from "react-bootstrap/ListGroup";
import {
  Accordion,
  Button,
  Form,
  Modal,
  Card,
  Dropdown,
  Table,
} from "react-bootstrap";
import ZymranIcon from "component/common/ZymranIcon";
import { ReactComponent as EmptyListIcon } from "assets/img/icons/empty-list.svg";

const ListServices = () => {
  const { t } = useTranslation();
  const selectedLocation = {
    value: 0,
    label: t("all"),
  };
  const [serviceCategories, setServiceCategories] = useState([]);

  const [serviceCategoriesPreviousLength, setServiceCategoriesPreviousLength] =
    useState(0);

  const [selectedServiceCategoryId, setSelectedServiceCategoryId] =
    useState("");
  const [services, setServices] = useState([]);
  const [serviceIdToDelete, setServiceIdToDelete] = useState("");
  const [serviceCategoriesDataList, setServiceCategoriesDataList] = useState(
    []
  );
  const [blocking, setBlocking] = useState(false);
  const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
  const [showCreateServiceModal, setShowCreateServiceModal] = useState(false);
  const [showCreateServiceCategoryModal, setShowCreateServiceCategoryModal] =
    useState(false);
  const [showEditServiceModal, setShowEditServiceModal] = useState(false);
  const [showEditServiceCategoryModal, setShowEditServiceCategoryModal] =
    useState(false);
  const [showDeleteServiceCategoryModal, setShowDeleteServiceCategoryModal] =
    useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [currentActiveKey, setCurrentActiveKey] = useState([]);
  const [accordionIds, setAccordionIds] = useState([]);
  const [filterText, setFilterText] = useState("");

  const openAll = () => {
    setCurrentActiveKey(accordionIds);
  };

  const toggleAccordion = () => {
    if (currentActiveKey.length !== 0) {
      setCurrentActiveKey([]);
    } else {
      setCurrentActiveKey(accordionIds);
    }
  };

  useEffect(() => {
    loadServiceCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAccordionIds(serviceCategories.map((category) => category.id));
    // eslint-disable-next-line
  }, [serviceCategories]);

  useEffect(() => {
    if (serviceCategories.length > 0 && serviceCategoriesPreviousLength === 0) {
      loadServices();
    }
    // eslint-disable-next-line
  }, [serviceCategories]);

  useEffect(() => {
    constructServicesTable();
    // eslint-disable-next-line
  }, [
    services.length,
    serviceCategories.length,
    serviceCategories,
    services,
    filterText,
  ]);

  const onEditServiceCategoryClick = (serviceCategoryId) => {
    setSelectedServiceCategoryId(serviceCategoryId);
    setShowEditServiceCategoryModal(true);
  };

  const onDeleteServiceCategoryClick = (serviceCategoryId) => {
    setSelectedServiceCategoryId(serviceCategoryId);
    setShowDeleteServiceCategoryModal(true);
  };

  const onDeleteServiceClick = (serviceId) => {
    setServiceIdToDelete(serviceId);
    setShowDeleteServiceModal(true);
  };

  const updateLocalServiceCategories = (serviceCategoryId) => {
    const newServiceCategories = serviceCategories.filter((sc) => {
      return sc.id !== serviceCategoryId;
    });
    setServiceCategories(newServiceCategories);
  };

  const handleDeleteServiceCategory = async () => {
    try {
      setBlocking(true);
      await deleteServiceCategory(selectedServiceCategoryId);
      updateLocalServiceCategories(selectedServiceCategoryId);
      notifyWarning(t("service_category_deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
      setShowDeleteServiceCategoryModal(false);
    }
  };

  const getLocationPrice = (locationPrices, locationId) => {
    const locationPrice = locationPrices.find(
      (price) => price.location.id === locationId
    );
    return locationPrice ? locationPrice.price : null;
  };

  const getLocationDuration = (locationPrices, locationId) => {
    const locationPrice = locationPrices.find(
      (price) => price.location.id === locationId
    );
    return locationPrice ? locationPrice.duration : null;
  };

  const constructServicesTable = () => {
    const isStaffMatch = (sls) =>
      sls.staffLocation.staff.firstName
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      sls.staffLocation.staff.lastName
        .toLowerCase()
        .includes(filterText.toLowerCase());

    const filteredServices = services
      .map((service) => {
        const matchedStaff = service.staffLocationServices.filter(isStaffMatch);

        return {
          ...service,
          staffLocationServices:
            filterText === "" ||
            service.name.toLowerCase().includes(filterText.toLowerCase())
              ? service.staffLocationServices
              : matchedStaff,
        };
      })
      .filter(
        (service) =>
          service.name.toLowerCase().includes(filterText.toLowerCase()) ||
          service.staffLocationServices.length > 0
      );

    const serviceCategoriesIncluded = new Set(
      serviceCategories.map((category) => category.id)
    );

    const filteredCategories =
      services.length === 0
        ? serviceCategories
        : serviceCategories.filter((category) =>
            serviceCategoriesIncluded.has(category.id)
          );

    const dataList = filteredCategories.map(
      (serviceCategory, index, { length }) => {
        const servicesForCategory = filteredServices.filter(
          (service) => service.serviceCategoryId === serviceCategory.id
        );

        if (selectedLocation.value === 0) {
          return (
            <Card
              key={serviceCategory.id}
              className={classNames("shadow-none")}
            >
              <Accordion.Item
                eventKey={serviceCategory.id}
                className="border-0"
              >
                <Card.Header
                  className="d-flex flex-row align-items-center"
                  id={`faqAccordionHeading${serviceCategory.id}`}
                >
                  <Accordion.Button className="btn text-decoration-none d-flex flex-row w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
                    <ZymranIcon
                      name="chevron-down"
                      size={24}
                      className="accordion-icon services-accordion-icon me-3"
                    />
                    <div className="d-flex flex-column primary-text-color gap-1">
                      <h3 className="mb-0">{serviceCategory.name}</h3>
                      <span className="p1">{serviceCategory.description}</span>
                    </div>
                  </Accordion.Button>
                  <div className="d-flex align-items-start gap-2">
                    <button
                      type="button"
                      className="icon-button icon-button--grey-light icon-button--sm"
                      onClick={() =>
                        onDeleteServiceCategoryClick(serviceCategory.id)
                      }
                    >
                      <ZymranIcon name="delete" />
                    </button>
                    <button
                      type="button"
                      className="icon-button icon-button--grey-light icon-button--sm"
                      onClick={() =>
                        onEditServiceCategoryClick(serviceCategory.id)
                      }
                    >
                      <ZymranIcon name="pen" />
                    </button>
                    <button
                      type="button"
                      className="icon-button icon-button--grey icon-button--sm"
                      onClick={() =>
                        handleCreateServiceClick(serviceCategory.id)
                      }
                    >
                      <ZymranIcon name="plus" />
                    </button>
                  </div>
                </Card.Header>
                <Accordion.Body className="p-0">
                  <Card.Body className="pt-2">
                    <div className="table-responsive table-rounded-wrapper">
                      <Table bordered className="services-table mb-0">
                        <thead className="p2-bold secondary-text-color">
                          <tr>
                            <th>
                              <Trans>service</Trans>
                            </th>
                            <th>
                              <Trans>location</Trans>
                            </th>
                            <th>
                              <Trans>staff</Trans>
                            </th>
                            <th>
                              <Trans>duration</Trans>
                            </th>
                            <th>
                              <Trans>price</Trans>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="p2 primary-text-color">
                          {servicesForCategory
                            .filter(
                              (service) =>
                                service.serviceCategoryId === serviceCategory.id
                            )
                            .map((service, index, filteredServices) => {
                              const {
                                name,
                                locationPrices,
                                staffLocationServices,
                              } = service;
                              const serviceLocations = [];
                              const staffGroupedData = {};
                              // Group staffLocationServices by location
                              const isLastService =
                                index === filteredServices.length - 1;
                              if (staffLocationServices.length > 0) {
                                staffLocationServices.forEach((sls) => {
                                  const locationId =
                                    sls.staffLocation.location.id;

                                  if (!staffGroupedData[locationId]) {
                                    staffGroupedData[locationId] = {
                                      locationName:
                                        sls.staffLocation.location.name,
                                      staff: [],
                                      prices: [],
                                      durations: [],
                                    };
                                    serviceLocations.push(locationId);
                                  }

                                  if (sls.staffLocation.staff) {
                                    staffGroupedData[locationId].staff.push(
                                      sls.staffLocation.staff
                                    );

                                    const price =
                                      sls.servicePrice &&
                                      sls.servicePrice.price !== null
                                        ? sls.servicePrice.price
                                        : getLocationPrice(
                                            locationPrices,
                                            locationId
                                          );
                                    staffGroupedData[locationId].prices.push(
                                      price
                                    );

                                    const duration =
                                      sls.servicePrice &&
                                      sls.servicePrice.duration !== null
                                        ? sls.servicePrice.duration
                                        : getLocationDuration(
                                            locationPrices,
                                            locationId
                                          );
                                    staffGroupedData[locationId].durations.push(
                                      duration
                                    );
                                  }
                                });

                                const totalRowsForService =
                                  serviceLocations.reduce((sum, locationId) => {
                                    const dataForLocation =
                                      staffGroupedData[locationId];
                                    const maxEntries = Math.max(
                                      dataForLocation.durations.length,
                                      dataForLocation.staff.length,
                                      dataForLocation.prices.length
                                    );
                                    return sum + maxEntries;
                                  }, 0);

                                return (
                                  <Fragment key={index}>
                                    {serviceLocations.flatMap(
                                      (locationId, locationIndex) => {
                                        const dataForLocation =
                                          staffGroupedData[locationId];
                                        const maxEntries = Math.max(
                                          dataForLocation.durations.length,
                                          dataForLocation.staff.length,
                                          dataForLocation.prices.length
                                        );
                                        const isLastLocation =
                                          locationIndex ===
                                          serviceLocations.length - 1;
                                        return Array.from({
                                          length: maxEntries,
                                        }).map((_, entryIndex) => (
                                          <tr
                                            key={`${index}_${locationIndex}_${entryIndex}`}
                                          >
                                            {locationIndex === 0 &&
                                              entryIndex === 0 && (
                                                <td
                                                  rowSpan={totalRowsForService}
                                                  className={
                                                    isLastService
                                                      ? "last-cell-no-bottom-border"
                                                      : ""
                                                  }
                                                >
                                                  {name}
                                                </td>
                                              )}
                                            {entryIndex === 0 && (
                                              <td
                                                rowSpan={maxEntries}
                                                className={
                                                  isLastLocation
                                                    ? "last-cell-no-bottom-border"
                                                    : ""
                                                }
                                              >
                                                {dataForLocation.locationName}
                                              </td>
                                            )}
                                            <td>
                                              {dataForLocation.staff[
                                                entryIndex
                                              ] &&
                                                `${dataForLocation.staff[entryIndex].firstName} ${dataForLocation.staff[entryIndex].lastName}`}
                                            </td>
                                            <td>
                                              {
                                                dataForLocation.durations[
                                                  entryIndex
                                                ]
                                              }
                                            </td>
                                            <td>
                                              {
                                                dataForLocation.prices[
                                                  entryIndex
                                                ]
                                              }
                                            </td>
                                            {locationIndex === 0 &&
                                              entryIndex === 0 && (
                                                <td
                                                  rowSpan={totalRowsForService}
                                                  className={
                                                    isLastService
                                                      ? "last-cell-no-bottom-border"
                                                      : ""
                                                  }
                                                >
                                                  <div className="d-flex flex-row gap-2">
                                                    <button
                                                      type="button"
                                                      className="icon-button icon-button--grey-light icon-button--sm"
                                                      onClick={() =>
                                                        onDeleteServiceClick(
                                                          service.id
                                                        )
                                                      }
                                                    >
                                                      <ZymranIcon name="delete" />
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="icon-button icon-button--grey-light icon-button--sm"
                                                      onClick={() =>
                                                        handleShowEditServiceModal(
                                                          service.id
                                                        )
                                                      }
                                                    >
                                                      <ZymranIcon name="pen" />
                                                    </button>
                                                  </div>
                                                </td>
                                              )}
                                          </tr>
                                        ));
                                      }
                                    )}
                                  </Fragment>
                                );
                              } else if(locationPrices.length > 0) {
                                return (
                                  <Fragment key={index}>
                                    {locationPrices.flatMap(
                                      (locationPrice, locationIndex) => {
                                        const isLastLocation =
                                          locationIndex ===
                                          locationPrices.length - 1;

                                        return Array.from({ length: 1 }).map(
                                          (_, entryIndex) => (
                                            <tr
                                              key={`${index}_${locationIndex}_${entryIndex}`}
                                            >
                                              {locationIndex === 0 &&
                                                entryIndex === 0 && (
                                                  <td
                                                    className={
                                                      isLastService
                                                        ? "last-cell-no-bottom-border"
                                                        : ""
                                                    }
                                                  >
                                                    {locationPrice.serviceName}
                                                  </td>
                                                )}
                                              {entryIndex === 0 && (
                                                <td
                                                  className={
                                                    isLastLocation
                                                      ? "last-cell-no-bottom-border"
                                                      : ""
                                                  }
                                                >
                                                  {
                                                    locationPrice?.location
                                                      ?.name
                                                  }
                                                </td>
                                              )}
                                              <td></td>
                                              <td>{locationPrice.duration}</td>
                                              <td>{locationPrice.price}</td>
                                              {locationIndex === 0 &&
                                                entryIndex === 0 && (
                                                  <td
                                                    className={
                                                      isLastService
                                                        ? "last-cell-no-bottom-border"
                                                        : ""
                                                    }
                                                  >
                                                    <div className="d-flex flex-row gap-2">
                                                      <button
                                                        type="button"
                                                        className="icon-button icon-button--grey-light icon-button--sm"
                                                        onClick={() =>
                                                          onDeleteServiceClick(
                                                            service.id
                                                          )
                                                        }
                                                      >
                                                        <ZymranIcon name="delete" />
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="icon-button icon-button--grey-light icon-button--sm"
                                                        onClick={() =>
                                                          handleShowEditServiceModal(
                                                            service.id
                                                          )
                                                        }
                                                      >
                                                        <ZymranIcon name="pen" />
                                                      </button>
                                                    </div>
                                                  </td>
                                                )}
                                            </tr>
                                          )
                                        );
                                      }
                                    )}
                                  </Fragment>
                                );
                              } else {
                                return (
                                  <tr key={index}>
                                    <td>{name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div className="d-flex flex-row gap-2">
                                        <button
                                          type="button"
                                          className="icon-button icon-button--grey-light icon-button--sm"
                                          onClick={() =>
                                            onDeleteServiceClick(service.id)
                                          }
                                        >
                                          <ZymranIcon name="delete" />
                                        </button>
                                        <button
                                          type="button"
                                          className="icon-button icon-button--grey-light icon-button--sm"
                                          onClick={() =>
                                            handleShowEditServiceModal(service.id)
                                          }
                                        >
                                          <ZymranIcon name="pen" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          {servicesForCategory.filter(
                            (service) =>
                              service.serviceCategoryId === serviceCategory.id
                          ).length === 0 && (
                            <tr className="text-center">
                              <td colSpan={6}>
                                <div className="d-flex flex-column justify-content-center align-items-center gap-4 py-3 empty-services">
                                  <EmptyListIcon />
                                  <p className="p2 mb-0">
                                    <Trans>
                                      services_has_not_been_added_yet
                                    </Trans>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Card>
          );
        } else if (serviceCategory.locationId === selectedLocation.value) {
          return (
            <Card key={serviceCategory.id}>
              <Card.Body>
                <Card.Title>{serviceCategory.name}</Card.Title>
                <Card.Subtitle>{serviceCategory.description}</Card.Subtitle>
                <ListGroup>
                  {constructServicesListGroups(serviceCategory.id)}
                </ListGroup>
              </Card.Body>
            </Card>
          );
        } else {
          return "";
        }
      }
    );
    setServiceCategoriesDataList(dataList);
  };

  const constructServicesListGroups = (serviceCategoryId) => {
    let result = services.map((service, index) => {
      if (service.serviceCategoryId === serviceCategoryId) {
        return (
          <ListGroup.Item
            key={service.id}
            action
            onClick={() => handleShowEditServiceModal(service.id)}
          >
            {service.name}
          </ListGroup.Item>
        );
      } else {
        return "";
      }
    });
    return result;
  };

  const loadServiceCategories = async () => {
    try {
      setBlocking(true);
      const response = await getServiceCategories();
      setServiceCategories(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const loadServices = async () => {
    try {
      setBlocking(true);
      const response = await getServices();
      setServices(response.data);
      setServiceCategoriesPreviousLength(serviceCategories.length);
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
    }
  };

  const handleDelete = async () => {
    try {
      setBlocking(true);
      await deleteService(serviceIdToDelete);
      deleteLocalService(serviceIdToDelete);
      notifySuccess(t("service_deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setBlocking(false);
      setShowDeleteServiceModal(false);
    }
  };

  const handleUpdateServices = (updatedService) => {
    setServices((oldServices) =>
      oldServices.map((s) => (s.id === updatedService.id ? updatedService : s))
    );
  };

  const handleCloseDeleteServiceModal = () => {
    setShowDeleteServiceModal(false);
  };

  const deleteLocalService = (serviceId) => {
    let servicesList = services.filter((serv, index) => {
      return serv.id !== serviceId;
    });
    setServices(servicesList);
  };

  const handleCreateServiceClick = (serviceCategoryId = null) => {
    if (serviceCategoryId !== null) {
      setSelectedServiceCategoryId(serviceCategoryId);
    }
    setShowCreateServiceModal(true);
  };

  const handleShowEditServiceModal = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowEditServiceModal(true);
  };

  const handleCreateServiceCategoryClick = () => {
    setShowCreateServiceCategoryModal(true);
  };

  const handleCreateServiceModalClose = () => {
    setShowCreateServiceModal(false);
  };

  const handleCloseEditServiceModal = () => {
    setShowEditServiceModal(false);
  };

  const handleCreateServiceCategoryModalClose = () => {
    setShowCreateServiceCategoryModal(false);
  };

  const handleEditServiceCategoryModalClose = () => {
    setShowEditServiceCategoryModal(false);
  };

  const handleDeleteServiceCategoryModalClose = () => {
    setShowDeleteServiceCategoryModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">
          <Trans>services</Trans>
        </h1>
        <div className="d-flex align-items-center gap-3">
          <Form className="search-box">
            <Form.Control
              type="search"
              placeholder="Search..."
              aria-label="Search"
              className="search-input search-input--sm"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value);
                openAll();
              }}
            />
            <ZymranIcon
              name="search"
              className="position-absolute search-box-icon"
            />
          </Form>

          <Button
            type="button"
            variant="grey"
            size="sm"
            onClick={toggleAccordion}
          >
            {currentActiveKey.length === 0 ? (
              <>
                <ZymranIcon name="chevron-down" />
                <Trans>open_all_accordion</Trans>
              </>
            ) : (
              <>
                <ZymranIcon name="chevron-up" />
                <Trans>close_all_accordion</Trans>
              </>
            )}
          </Button>
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="blue"
              size="sm"
              id="createDropdown"
              className="service-category-add-dropdown-toggle"
            >
              <ZymranIcon name="plus" />
              <Trans>add</Trans>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleCreateServiceClick()}
                disabled={serviceCategoriesDataList.length === 0}
              >
                <Trans>new_service</Trans>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleCreateServiceCategoryClick}>
                <Trans>new_category</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {serviceCategoriesDataList.length === 0 ? (
        <Card>
          <Card.Body>
            <div className="d-flex flex-column justify-content-center align-items-center gap-4 py-3 empty-services">
              <EmptyListIcon />
              <p className="p2 mb-0">
                <Trans>categories_has_not_been_added_yet</Trans>
              </p>
              <Button
                name="addServiceCategoryButton"
                variant="blue"
                size="sm"
                type="button"
                onClick={handleCreateServiceCategoryClick}
              >
                <ZymranIcon name="plus" />
                <Trans>new_category</Trans>
              </Button>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Accordion
          id="accordionFaq"
          className="rounded services-accordion"
          alwaysOpen
          onSelect={(eventKey) => {
            setCurrentActiveKey(eventKey);
          }}
          activeKey={currentActiveKey}
        >
          {serviceCategoriesDataList}
        </Accordion>
      )}

      {/* Create ServiceCategory Modal */}
      <Modal
        show={showCreateServiceCategoryModal}
        onHide={handleCreateServiceCategoryModalClose}
        centered
      >
        <Modal.Header className="plain-modal-header bg-primary">
          <Modal.Title className="text-white light">
            <Trans>adding_category</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="plain-modal-body">
          <CreateCategory
            handleClose={handleCreateServiceCategoryModalClose}
            serviceCategories={serviceCategories}
            setServiceCategories={setServiceCategories}
          />
        </Modal.Body>
      </Modal>

      {/* Edit ServiceCategory Modal */}
      <Modal
        show={showEditServiceCategoryModal}
        onHide={handleEditServiceCategoryModalClose}
        centered
      >
        <Modal.Header className="plain-modal-header">
          <Modal.Title>
            <Trans>edit_category</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="plain-modal-body">
          <EditCategory
            categoryId={selectedServiceCategoryId}
            serviceCategories={serviceCategories}
            setServiceCategories={setServiceCategories}
            handleClose={handleEditServiceCategoryModalClose}
          />
        </Modal.Body>
      </Modal>

      {/* Create Service Modal */}
      <Modal
        show={showCreateServiceModal}
        onHide={handleCreateServiceModalClose}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>add</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateService
            categoryId={selectedServiceCategoryId}
            handleClose={handleCreateServiceModalClose}
            setServices={setServices}
          />
        </Modal.Body>
      </Modal>

      {/* Edit Service Modal */}
      <Modal
        show={showEditServiceModal}
        onHide={handleCloseEditServiceModal}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>save</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditService
            serviceId={selectedServiceId}
            categoryId={selectedServiceCategoryId}
            handleClose={handleCloseEditServiceModal}
            // setServiceUpdated={setServiceUpdated}
            onUpdate={handleUpdateServices}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Service Category Modal */}
      <Modal
        show={showDeleteServiceCategoryModal}
        onHide={handleDeleteServiceCategoryModalClose}
        className="confirmation-modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center align-self-stretch text-center">
            <h2>
              <Trans>sure_to_delete_service_category</Trans>
            </h2>
            <p className="mb-0 p1 secondary-text-color">
              <Trans>all_service_category_data_will_be_deleted</Trans>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="orange"
            size="sm"
            className="flex-grow-1"
            onClick={handleDeleteServiceCategory}
          >
            <Trans>delete_category</Trans>
          </Button>
          <Button
            variant="blue"
            size="sm"
            className="flex-grow-1"
            onClick={handleDeleteServiceCategoryModalClose}
          >
            <Trans>cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Service Modal */}
      <Modal
        show={showDeleteServiceModal}
        onHide={handleCloseDeleteServiceModal}
        className="confirmation-modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center align-self-stretch text-center">
            <h2>
              <Trans>do_you_want_to_delete_service</Trans>
            </h2>
            <p className="mb-0 p1 secondary-text-color">
              <Trans>all_service_data_will_be_deleted</Trans>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="orange"
            size="sm"
            className="flex-grow-1"
            onClick={handleDelete}
          >
            <Trans>delete_service</Trans>
          </Button>
          <Button
            variant="blue"
            size="sm"
            className="flex-grow-1"
            onClick={handleCloseDeleteServiceModal}
          >
            <Trans>cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListServices;
