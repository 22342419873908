import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Calendar, Views, luxonLocalizer } from "react-big-calendar";
import { getCountOfFinishedAppointments } from "service/customer/CustomerService";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { getStaffSchedules } from "../../service/staff-schedule/StaffScheduleService";
import BlockUI from "../../util/block-ui/block-ui";
import ZymranIcon from "../common/ZymranIcon";
import ViewAppointment from "./ViewAppointment";
import NewCheckout from "./checkout/NewCheckout";
import CreateAppointment from "./create/CreateAppointment";
import EditAppointment from "./edit/EditAppointment";

import {
  deleteAppointment,
  getAppointments,
  updateAppointments,
} from "../../service/appointment/AppointmentService";

import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";

import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Button, Card, Col, OverlayTrigger, Popover } from "react-bootstrap";

import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Trans } from "react-i18next";
import Select, { components } from "react-select";
import { getLocations } from "../../service/location/LocationService";
import { notifySuccess } from "../../util/notify";

import {
  APPOINTMENT_STATUS,
  CALENDAR_END_HOUR,
  CALENDAR_START_HOUR,
  CALENDAR_STEP,
} from "util/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ZymranAvatar from "component/common/ZymranAvatar";
import EditStaff from "component/staff/EditStaff";
import { Link } from "react-router-dom";
import tumbleweed from "styles/resources/tumbleweed.png";
import "./styles.css";

import {
  endOfWeek,
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfWeek,
} from "date-fns";
import useLocale from "hooks/useLocale";

import loadingAnimation from "assets/img/animated-icons/loading.json";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import capitalize from "lodash/capitalize";
import Lottie from "lottie-react";
import { navigate } from "react-big-calendar/lib/utils/constants";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Scheduler = () => {
  const [staffSchedules, setStaffSchedules] = useState();
  const [appointmentsFromDB, setAppointmentsFromDB] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsCopy, setAppointmentsCopy] = useState([]);
  const [deletedAppointments, setDeletedAppointments] = useState([]);
  const minimumDuration = 15;
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(0);
  const [timezone, setTimezone] = useState(DateTime.local().zoneName);
  // This is a currenDate starting at today 00:00 current local time
  const [currentDate, setCurrentDate] = useState(
    fromZonedTime(
      setHours(
        setMinutes(
          setSeconds(setMilliseconds(DateTime.now().toJSDate(), 0), 0),
          0
        ),
        0
      ),
      timezone
    )
  );

  // These are start and end of a working day in a local time
  const [calendarStartTime, setCalendarStartTime] = useState(
    fromZonedTime(setHours(currentDate, CALENDAR_START_HOUR), timezone)
  );

  // Values are set when clicking on appointment in function handleSelectEvent
  const [appointment, setAppointment] = useState({
    id: 1,
    startTime: calendarStartTime,
    duration: minimumDuration,
    price: 0,
    resourceId: 0,
    selectedStaffLocation: {
      value: 0,
      label: "",
    },
    staffPosition: "",
    selectedService: {
      value: 0,
      label: "",
    },
    chainId: null,
    customerId: null,
  });

  const [currentDateFormatted, setCurrentDateFormatted] = useState("");

  const [calendarEndTime, setCalendarEndTime] = useState(
    toZonedTime(setHours(currentDate, CALENDAR_END_HOUR), timezone)
  );

  // Staff members on a horizontal axis
  const [resourceMap, setResourceMap] = useState([]);
  // These are appointments in the calendar
  const [events, setEvents] = useState([]);
  const [localizer, setLocalizer] = useState(luxonLocalizer(DateTime));
  const views = ["day"];
  // const {
  //   config: { isDark },
  // } = useContext(AppContext);

  const [countOfFinishedAppointments, setCountOfFinishedAppointments] =
    useState(0);

  const [showCreateAppointmentModal, setShowCreateAppointmentModal] =
    useState(false);
  const [showEditAppointmentModal, setShowEditAppointmentModal] =
    useState(false);
  const [showViewAppointmentModal, setShowViewAppointmentModal] =
    useState(false);
  const [showAppointmentCheckoutModal, setShowAppointmentCheckoutModal] =
    useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);

  const [locationOptions, setLocationOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocationOption, setSelectedLocationOption] = useState({
    value: 0,
    label: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [blocking, setBlocking] = useState(false);
  const locationSelect = useRef();

  const [activePopoverId, setActivePopoverId] = useState(null);

  const handleTogglePopover = (eventId) => {
    setActivePopoverId((prevId) => (prevId === eventId ? null : eventId));
  };

  const { t } = useTranslation();

  const locale = useLocale();

  const onNavigate = useCallback(
    (newCurrentDate) => {
      setCurrentDate(newCurrentDate);
      setCalendarStartTime(
        fromZonedTime(setHours(newCurrentDate, CALENDAR_START_HOUR), timezone)
      );
      setCalendarEndTime(
        fromZonedTime(setHours(newCurrentDate, CALENDAR_END_HOUR), timezone)
      );
    },
    [setCurrentDate, timezone]
  );

  const handleShowEditAppointmentModalFromView = () => {
    setShowEditAppointmentModal(true);
  };

  const handleCloseAppointmentCheckout = () => {
    setShowAppointmentCheckoutModal(false);
  };

  const makeSameDate = (time) => {
    return moment()
      .startOf("day")
      .hour(moment(time).hour())
      .minute(moment(time).minute());
  };

  const extractTime = (date) => {
    return moment()
      .startOf("day")
      .hour(moment(date).hour())
      .minute(moment(date).minute());
  };

  const withinEffectiveRange = (
    date,
    shiftEffectiveStartDate,
    shiftEffectiveEndDate
  ) => {
    return (
      (shiftEffectiveStartDate === null || date >= shiftEffectiveStartDate) &&
      (shiftEffectiveEndDate === null || date < shiftEffectiveEndDate)
    );
  };

  const slotPropGetter = useCallback(
    (date, resourceId) => {
      let slwh = {};

      if (
        staffSchedules &&
        Array.isArray(staffSchedules) &&
        staffSchedules.length > 0 &&
        selectedLocationOption.value !== 0 &&
        resourceId !== undefined
      ) {
        slwh = getStaffLocationWorkingHours(resourceId);
      }

      if (slwh !== undefined) {
        const currentTime = moment(date);

        const primaryShiftStart = makeSameDate(slwh.primaryShiftStart);
        const primaryShiftEnd = makeSameDate(slwh.primaryShiftEnd);
        const secondaryShiftStart = makeSameDate(slwh.secondaryShiftStart);
        const secondaryShiftEnd = makeSameDate(slwh.secondaryShiftEnd);

        const shiftEffectiveStartDate = slwh.shiftEffectiveStartDate
          ? moment(slwh.shiftEffectiveStartDate).startOf("day")
          : null;
        const shiftEffectiveEndDate = slwh.shiftEffectiveEndDate
          ? moment(slwh.shiftEffectiveEndDate).startOf("day")
          : null;

        const inPrimaryShift =
          extractTime(currentTime) >= extractTime(primaryShiftStart) &&
          extractTime(currentTime) < extractTime(primaryShiftEnd);
        const inSecondaryShift =
          extractTime(currentTime) >= extractTime(secondaryShiftStart) &&
          extractTime(currentTime) < extractTime(secondaryShiftEnd);

        if (slwh.dayOff) {
          return {
            className: ["rbc-time-slot", "rbc-inactive-time-slot"],
          };
        } else if (
          (inPrimaryShift || inSecondaryShift) &&
          withinEffectiveRange(
            currentTime,
            shiftEffectiveStartDate,
            shiftEffectiveEndDate
          )
        ) {
          return {
            className: "rbc-active-slot",
            style: {
              color: "black",
              userSelect: "none",
            },
          };
        } else {
          return {
            className: ["rbc-time-slot", "rbc-inactive-time-slot"],
          };
        }
      }
    },
    // eslint-disable-next-line
    [
      resourceMap.length,
      calendarStartTime,
      staffSchedules,
      selectedLocationOption.value,
    ]
  );

  const getStaffLocationWorkingHours = (resourceId) => {
    const staffSchedule = staffSchedules.find((sl) => {
      return (
        sl.location.id === selectedLocationOption.value && sl.id === resourceId
      );
    });

    let staffLocationWorkingHours;

    if (staffSchedule !== undefined) {
      staffLocationWorkingHours = staffSchedule.staffLocationWorkingHours.find(
        (item) => {
          // TODO: change for current day name
          const weekdayName = format(
            toZonedTime(calendarStartTime, timezone),
            "EEEE"
          ).toLowerCase();
          return item.dayOfWeek === weekdayName;
        }
      );
    }

    if (staffLocationWorkingHours !== undefined) {
      return {
        id: staffLocationWorkingHours.id,
        primaryShiftStart: staffLocationWorkingHours.primaryShiftStart,
        primaryShiftEnd: staffLocationWorkingHours.primaryShiftEnd,
        secondaryShiftStart: staffLocationWorkingHours.secondaryShiftStart,
        secondaryShiftEnd: staffLocationWorkingHours.secondaryShiftEnd,
        shiftEffectiveStartDate:
          staffLocationWorkingHours.shiftEffectiveStartDate,
        shiftEffectiveEndDate: staffLocationWorkingHours.shiftEffectiveEndDate,
        dayOff: staffLocationWorkingHours.dayOff,
      };
    }
  };

  const updateCurrentDateFormatted = (date) => {
    setCurrentDateFormatted(
      format(date, "dd MMM yyyy", {
        locale,
      })
    );
  };

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (locationOptions.length > 0 && selectedLocationOption.value !== 0) {
      loadStaffSchedule();
    }
    // eslint-disable-next-line
  }, [selectedLocationOption.value, locationOptions.length]);

  useEffect(() => {
    if (selectedLocation?.address?.timezone) {
      setTimezone(selectedLocation.address.timezone);
    }
    // eslint-disable-next-line
  }, [selectedLocation.id]);

  useEffect(() => {
    DateTime.local().setZone(timezone);
    setLocalizer(luxonLocalizer(DateTime));
    const currentDate = fromZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(moment().toDate(), 0), 0), 0),
        0
      ),
      timezone
    );

    const calendarStartTime = fromZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(moment().toDate(), 0), 0), 0),
        CALENDAR_START_HOUR
      ),
      timezone
    );

    const calendarEndTime = fromZonedTime(
      setHours(
        setMinutes(setSeconds(setMilliseconds(moment().toDate(), 0), 0), 0),
        CALENDAR_END_HOUR
      ),
      timezone
    );
    setCurrentDate(currentDate);
    setCalendarStartTime(calendarStartTime);
    setCalendarEndTime(calendarEndTime);
    // eslint-disable-next-line
  }, [timezone]);

  useEffect(() => {
    if (
      staffSchedules &&
      Array.isArray(staffSchedules) &&
      staffSchedules.length > 0
    ) {
      prepareResourceMap();
    }
    // eslint-disable-next-line
  }, [staffSchedules, selectedLocationOption.value]);

  useEffect(() => {
    if (resourceMap.length > 0) {
      loadAppointments();
    }
    // eslint-disable-next-line
  }, [resourceMap.length, calendarStartTime]);

  useEffect(() => {
    prepareEvents();
    // eslint-disable-next-line
  }, [appointmentsFromDB.length, appointmentsFromDB]);

  useEffect(() => {
    updateCurrentDateFormatted(currentDate);
    // eslint-disable-next-line
  }, [currentDate]);

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const loadLocations = async () => {
    try {
      setLoadingCounter((counter) => counter + 1);
      const response = await getLocations();
      const locations = response.data;
      if (locations.length > 0) {
        let locOptions = [];
        locations.forEach((loc) =>
          locOptions.push({ value: loc.id, label: loc.name })
        );
        // Sort locations
        locOptions.sort((loc1, loc2) => (loc1.value > loc2.value ? 1 : -1));
        locations.sort((loc1, loc2) => (loc1.id > loc2.id ? 1 : -1));
        setLocations(locations);
        setLocationOptions(locOptions);
        setSelectedLocation(locations[0]);
        setSelectedLocationOption(locOptions[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter((counter) => counter - 1);
    }
  };

  const loadStaffSchedule = async () => {
    try {
      setLoadingCounter((counter) => counter + 1);
      const response = await getStaffSchedules(
        selectedLocationOption.value,
        fromZonedTime(startOfWeek(currentDate), timezone),
        fromZonedTime(endOfWeek(currentDate), timezone)
      );
      setStaffSchedules(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter((counter) => counter - 1);
    }
  };

  const loadAppointments = async () => {
    try {
      setLoadingCounter((counter) => counter + 1);
      const payload = {
        currentDate: currentDate,
      };
      const response = await getAppointments(payload);
      const appts = response.data;
      const newAppts = await Promise.all(
        appts.map(async (appt) => {
          const count = appt.customer
            ? await loadNumberOfServicesProvided(appt.customer?.id)
            : 0;
          return {
            ...appt,
            startTime: appt.startTime,
            endTime: appt.endTime,
            duration: calculateDuration(appt.startTime, appt.endTime),
            resourceId: appt.staffLocation.id,
            selectedStaffLocation: {
              value: appt.staffLocation.id,
              label:
                appt.staffLocation.staff?.firstName +
                " " +
                appt.staffLocation.staff?.lastName,
            },
            staffPosition: appt.staffLocation.staff?.position,
            selectedService: {
              value: appt.service.id,
              label: appt.service.name,
            },
            service: appt.service,
            chainId: appt.chainId,
            customerId: appt.customer?.id,
            customer: appt.customer,
            status: appt.status,
            checkoutInfo: appt.checkoutInfo,
            price: appt.service?.price,
            customerCountOfFinishedAppointments: count,
          };
        })
      );
      setAppointmentsFromDB(newAppts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCounter((counter) => counter - 1);
    }
  };

  const loadNumberOfServicesProvided = async (customerId) => {
    try {
      const response = customerId
        ? await getCountOfFinishedAppointments(customerId)
        : { data: 0 };
      setCountOfFinishedAppointments(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const prepareResourceMap = () => {
    const resourceList = [];
    staffSchedules.forEach((sl) => {
      const resource = {};
      if (selectedLocationOption.value === sl.location.id) {
        resource.resourceId = sl.id;
        resource.staffId = sl.staff.id;
        resource.resourceTitle = sl.staff.firstName + " " + sl.staff.lastName;
        resource.avatarUrl = sl.staff.avatarUrl;
        resource.position = sl.staff.position;
        resourceList.push(resource);
      }
    });
    setResourceMap(resourceList);
  };

  const prepareEvents = () => {
    const appointments = [];
    appointmentsFromDB.forEach((appointment) => {
      const appt = {};
      appt.id = appointment.id;
      appt.title = appointment.selectedService.label;
      appt.staffName = appointment.selectedStaffLocation.label;
      appt.resourceId = appointment.selectedStaffLocation.value;
      appt.staffPosition = appointment.staffPosition;
      appt.serviceId = appointment.selectedService.value;
      appt.service = appointment.service;
      appt.customer = appointment.customer;
      appt.customerCountOfFinishedAppointments =
        appointment.customerCountOfFinishedAppointments;
      appt.status = appointment.status;
      appt.checkoutInfo = appointment.checkoutInfo;
      appt.start = new Date(appointment.startTime);
      appt.end = new Date(appointment.endTime);
      appt.duration = calculateDuration(
        appointment.startTime,
        appointment.endTime
      );
      appt.chainId = appointment.chainId;
      appt.edited = false;
      appointments.push(appt);
    });
    setEvents(appointments);
  };

  // let formats = {
  //   tomeGutterFormat: (date, culture, localizer) =>
  //     localizer.format(localizer, "H:mm", culture),
  // };

  const onChangeLocationOption = (loc) => {
    setSelectedLocationOption(loc);
    const selectedLocation = locations.find((item) => {
      return item.id === loc.value;
    });
    setSelectedLocation(selectedLocation);
  };

  const handleMoveEvent = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) => {
      try {
        if (
          event.status.name === APPOINTMENT_STATUS.FINISHED ||
          event.status.name === APPOINTMENT_STATUS.CANCELLED
        ) {
          return;
        }
        const { allDay } = event;
        if (!allDay && droppedOnAllDaySlot) {
          event.allDay = true;
        }

        setEvents((prev) => {
          const existing = prev.find((ev) => ev.id === event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== event.id);
          return [...filtered, { ...existing, start, end, resourceId, allDay }];
        });

        const appointment = {
          id: event.id,
          startTime: fromZonedTime(start, timezone),
          endTime: fromZonedTime(end, timezone),
          resourceId: resourceId,
          staffLocationId: resourceId,
          serviceId: event.serviceId,
          chainId: event.chainId,
          customer: event.customer,
        };
        sendUpdateAppointment(appointment);
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line
    [setEvents, selectedLocationOption.value, appointmentsFromDB]
  );

  const handleResizeEvent = useCallback(
    ({ event, start, end }) => {
      try {
        if (
          event.status.name === APPOINTMENT_STATUS.FINISHED ||
          event.status.name === APPOINTMENT_STATUS.CANCELLED
        ) {
          return;
        }
        const appointment = {
          id: event.id,
          startTime: fromZonedTime(start, timezone),
          endTime: fromZonedTime(end, timezone),
          resourceId: event.resourceId,
          staffLocationId: event.resourceId,
          serviceId: event.serviceId,
          chainId: event.chainId,
          customer: event.customer,
        };
        sendUpdateAppointment(appointment);
        setEvents((prev) => {
          const existing = prev.find((ev) => ev.id === event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== event.id);
          return [...filtered, { ...existing, start, end }];
        });
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line
    [setEvents, selectedLocationOption.value, appointmentsFromDB]
  );

  const getMinutes = (date) => {
    return moment(date).minutes() + moment(date).hours() * 60;
  };

  const handleSelectSlot = useCallback(
    ({ resourceId, start, end }) => {
      if (start < calendarStartTime) {
        return;
      }
      if (staffSchedules && Array.isArray(staffSchedules)) {
        let slwh = getStaffLocationWorkingHours(resourceId);
        if (slwh && !slwh.dayOff) {
          let primaryShiftStartMinutes = getMinutes(
            toZonedTime(slwh.primaryShiftStart, timezone)
          );
          let primaryShiftEndMinutes = getMinutes(
            toZonedTime(slwh.primaryShiftEnd, timezone)
          );
          let secondaryShiftStartMinutes = getMinutes(
            toZonedTime(slwh.secondaryShiftStart, timezone)
          );
          let secondaryShiftEndMinutes = getMinutes(
            toZonedTime(slwh.secondaryShiftEnd, timezone)
          );
          let slotStartMinutes = getMinutes(toZonedTime(start, timezone));
          let slotEndMinutes = getMinutes(toZonedTime(end, timezone));
          if (
            !(
              (slotStartMinutes >= primaryShiftStartMinutes &&
                slotStartMinutes < primaryShiftEndMinutes) ||
              (slotStartMinutes >= secondaryShiftStartMinutes &&
                slotStartMinutes < secondaryShiftEndMinutes)
            ) ||
            !(
              (slotEndMinutes > primaryShiftStartMinutes &&
                slotEndMinutes <= primaryShiftEndMinutes) ||
              (slotEndMinutes > secondaryShiftStartMinutes &&
                slotEndMinutes <= secondaryShiftEndMinutes)
            )
          ) {
            setShowWarningModal(true);
          }
        } else if (slwh && slwh.dayOff) {
          setShowWarningModal(true);
        }
      }
      setAppointment({
        ...appointment,
        startTime: toZonedTime(start, timezone),
        endTime: toZonedTime(end, timezone),
        resourceId: resourceId,
      });
      setShowCreateAppointmentModal(true);
    },
    // eslint-disable-next-line
    [
      setAppointment,
      appointment,
      calendarStartTime,
      timezone,
      appointments,
      staffSchedules,
    ]
  );

  const handleCreateAppointment = () => {
    setAppointment({
      ...appointment,
      startTime: toZonedTime(moment().toDate(), timezone),
    });
    setShowCreateAppointmentModal(true);
  };

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: calendarStartTime,
      scrollToTime: new Date(2024, 0, 1, 8),
    }),
    // eslint-disable-next-line
    [calendarStartTime, timezone]
  );

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    // Calculate the duration in minutes
    const duration = (end - start) / 60000;
    return duration;
  };

  const sendUpdateAppointment = async (appointment) => {
    try {
      setBlocking(true);
      const appts = [];
      appointment.customerId = appointment.customer && appointment.customer.id;
      appts.push(appointment);

      const response = await updateAppointments(appts);

      if (response.status === 200) {
        const appt = response.data[0];
        const appointment = {
          id: appt.id,
          startTime: toZonedTime(appt.startTime, timezone),
          endTime: toZonedTime(appt.endTime, timezone),
          duration: calculateDuration(appt.startTime, appt.endTime),
          resourceId: appt.staffLocation.id,
          customer: appt.customer,
          selectedStaffLocation: {
            value: appt.staffLocation.id,
            label:
              appt.staffLocation.staff.firstName +
              " " +
              appt.staffLocation.staff.lastName,
          },
          staffPosition: appt.staffLocation.staff.position,
          selectedService: {
            value: appt.service.id,
            label: appt.service.name,
          },
          fromDB: true,
          toSave: false,
          toDelete: false,
        };
        updateLocalAppointment(appointment);
        setBlocking(false);
        setShowViewAppointmentModal(false);
        notifySuccess(t("appointment_updated"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBlocking(false);
    }
  };

  const updateLocalAppointment = (appointment) => {
    try {
      const updatedAppointments = appointmentsFromDB.map((appt) => {
        if (appt.id === appointment.id) {
          return {
            ...appt,
            startTime: appointment.startTime,
            endTime: appointment.endTime,
            duration: calculateDuration(
              appointment.startTime,
              appointment.endTime
            ),
            resourceId: appointment.selectedStaffLocation.value,
            customer: appointment.customer,
            selectedStaffLocation: appointment.selectedStaffLocation,
            staffPosition: appointment.staffPosition,
            selectedService: appointment.selectedService,
          };
        }
        return appt;
      });
      setAppointmentsFromDB(updatedAppointments);
      setAppointments([]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateCheckoutInfo = (checkoutInfoData, chainId) => {
    const updatedAppointments = appointments.map((appt) => {
      if (appt.chainId === chainId) {
        return {
          ...appt,
          checkoutInfo: checkoutInfoData,
          status: { name: APPOINTMENT_STATUS.FINISHED },
        };
      }
      return appt;
    });
    const updatedAppointmentsFromDB = appointmentsFromDB.map((appt) => {
      if (appt.chainId === chainId) {
        return {
          ...appt,
          checkoutInfo: checkoutInfoData,
          status: { name: APPOINTMENT_STATUS.FINISHED },
        };
      }
      return appt;
    });
    setAppointments(updatedAppointments);
    setAppointmentsFromDB(updatedAppointmentsFromDB);
  };

  const deleteFromAppointments = (appointment) => {
    const updatedAppointments = appointmentsFromDB.filter(
      (appt) => appt.id !== appointment.id
    );
    setAppointmentsFromDB(updatedAppointments);
  };

  const handleDeleteSingleAppointment = async () => {
    try {
      setDeletedAppointments((oldDeletedAppointments) => [
        ...oldDeletedAppointments,
        appointment,
      ]);
      await deleteAppointment(appointment.id);
      deleteFromAppointments(appointment);
      notifySuccess(t("appointment_removed"));
    } catch (error) {
      console.error(error);
    } finally {
      setShowDeleteConfirmation(false);
    }
    setAppointments([]);
  };

  const handleCloseCreateAppointmentModal = () => {
    setShowCreateAppointmentModal(false);
  };

  const handleAppointmentCreated = (appointment) => {
    setAppointment(appointment);
    setShowCreateAppointmentModal(false);
    setShowViewAppointmentModal(true);
  };

  const handleShowEditAppointmentModal = () => {
    setAppointmentsCopy(appointments);
  };

  const handleShowViewAppointmentModal = () => {
    setAppointmentsCopy(appointments);
  };

  const handleCloseEditAppointmentModal = () => {
    setShowEditAppointmentModal(false);
    setAppointments(appointmentsCopy);
  };

  const handleCloseViewAppointmentModal = () => {
    setShowViewAppointmentModal(false);
    setAppointments(appointmentsCopy);
  };

  const handleShowEditStaffModal = (staffId) => {
    setSelectedStaffId(staffId);
    setShowEditStaffModal(true);
  };

  const handleStaffUpdate = (updatedStaff) => {
    const updatedResourceMap = resourceMap.map((resource) => {
      if (resource.staffId === updatedStaff.id) {
        return {
          ...resource,
          resourceTitle: updatedStaff.firstName + " " + updatedStaff.lastName,
          staffId: updatedStaff.id,
        };
      }
      return resource;
    });
    setResourceMap(updatedResourceMap);
  };

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      className: "rbc-event-orange",
    }),
    []
  );

  const CustomHeader = (props) => {
    const avatarUrl = props.resource.avatarUrl;
    return (
      <>
        <Link
          to="#"
          onClick={() => handleShowEditStaffModal(props.resource.staffId)}
          className="rbc-employee-card"
        >
          <ZymranAvatar
            avatarUrl={avatarUrl}
            size="lg"
            shape="circle"
            fullName={`${props.label}`}
          />
          <div className="rbc-employee-name-block">
            <div className="mt-2 p2 rbc-employee-name">{props.label}</div>
            <div className="p3 rbc-employee-job">{props.resource.position}</div>
          </div>
        </Link>
      </>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ZymranIcon name="chevron-down" />
      </components.DropdownIndicator>
    );
  };

  const CustomInput = forwardRef((props, ref) => {
    return (
      <button
        name="rbcCalendarButton"
        className="rbc-calendar-button icon-button icon-button--sm icon-button--grey"
        onClick={props.onClick}
        value={`${currentDateFormatted}`}
      >
        <ZymranIcon name="calendar" className="rbc-toolbar-calendar-icon" />
      </button>
    );
  });

  const CustomRBCToolbar = (props) => {
    const goToBack = () => {
      props.onNavigate(navigate.PREVIOUS);
    };

    const goToNext = () => {
      props.onNavigate(navigate.NEXT);
    };

    const goToCurrent = () => {
      const today = toZonedTime(
        setHours(
          setMinutes(setSeconds(setMilliseconds(moment().toDate(), 0), 0), 0),
          0
        ),
        timezone
      );
      props.onNavigate("date", today);
    };

    // locale.localize;
    const formattedLabel = format(
      toZonedTime(props.date, timezone),
      "EEEE, dd MMM yyyy",
      { locale }
    );

    return (
      <div className="rbc-toolbar row align-items-center">
        <div className="left-side">
          <Button
            name="createAppointment"
            className="ms-2"
            variant="blue"
            size="sm"
            onClick={handleCreateAppointment}
          >
            <FontAwesomeIcon
              icon="plus"
              className="me-1"
              transform="shrink-3"
            />
            <Trans>create_appointment</Trans>
          </Button>
          <Select
            id="location"
            name="location"
            placeholder={t("select_location")}
            components={{ DropdownIndicator }}
            value={selectedLocationOption}
            options={locationOptions}
            onChange={onChangeLocationOption}
            ref={locationSelect}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            className="rbc-header-select"
            styles={{
              container: (base) => ({
                ...base,
                zIndex: 9999,
                minWidth: "150px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "6 12px",
                gap: "8px",
                borderRadius: "8px",
                flex: "none",
                order: 1,
                flexGrow: 0,
              }),
              menu: (base) => ({
                ...base,
                minWidth: "100%",
              }),
            }}
          />
        </div>
        <div className="middle-side">
          <button
            name="rbcLeftArrow"
            className="rbc-toolbar-arrow"
            onClick={goToBack}
          >
            <ZymranIcon
              name="arrow-left"
              className="rbc-toolbar-calendar-icon"
            />
          </button>
          <div className="rbc-toolbar-label">
            <h2 className="mb-0">{capitalize(formattedLabel)}</h2>
          </div>
          <button
            name="rbcRightArrow"
            className="rbc-toolbar-arrow"
            onClick={goToNext}
          >
            <ZymranIcon
              name="arrow-right"
              className="rbc-toolbar-calendar-icon "
            />
          </button>
        </div>
        <div className="right-side">
          <div className="custom-datepicker-wrapper me-2">
            <DatePicker
              selected={calendarStartTime}
              onChange={(date) => setCalendarStartTime(date)}
              dateFormat="dd/MM/yyyy"
              locale={locale}
              customInput={<CustomInput />}
            />
          </div>
          <button
            name="todayButton"
            className="rbc-today-button btn btn-grey"
            onClick={goToCurrent}
          >
            <Trans>today</Trans>
          </button>
        </div>
      </div>
    );
  };

  const handleOpenEditAppointmentModal = (event, hidePopover) => {
    const appointment = {
      id: event.id,
      startTime: toZonedTime(event.start, timezone),
      endTime: toZonedTime(event.end, timezone),
      resourceId: event.resourceId,
      chainId: event.chainId,
      customer: event.customer,
      status: event.status,
      checkoutInfo: event.checkoutInfo,
      comment: event.comment,
      serviceId: event.serviceId,
    };
    setAppointment(appointment);
    hidePopover();
    setShowEditAppointmentModal(true);
  };

  const handleOpenCheckoutModal = (event, hidePopover) => {
    const appointment = {
      id: event.id,
      startTime: toZonedTime(event.start, timezone),
      endTime: toZonedTime(event.end, timezone),
      resourceId: event.resourceId,
      chainId: event.chainId,
      customer: event.customer,
      status: event.status,
      checkoutInfo: event.checkoutInfo,
      comment: event.comment,
      serviceId: event.serviceId,
    };
    setAppointment(appointment);
    hidePopover();
    setShowAppointmentCheckoutModal(true);
  };

  const handleOpenViewAppointmentModal = (event, hidePopover) => {
    const appointment = {
      id: event.id,
      startTime: toZonedTime(event.start, timezone),
      endTime: toZonedTime(event.end, timezone),
      resourceId: event.resourceId,
      chainId: event.chainId,
      customer: event.customer,
      status: event.status,
      checkoutInfo: event.checkoutInfo,
      comment: event.comment,
      serviceId: event.serviceId,
    };
    setAppointment(appointment);
    hidePopover();
    setShowViewAppointmentModal(true);
  };

  const handleOpenViewAppointmentModalFromCheckout = (appointment) => {
    setAppointment(appointment);
    setShowViewAppointmentModal(true);
  };

  const handleOpenDeleteConfirmation = (event, hidePopover) => {
    const appointment = {
      id: event.id,
      startTime: toZonedTime(event.start, timezone),
      endTime: toZonedTime(event.end, timezone),
      resourceId: event.resourceId,
      chainId: event.chainId,
      customer: event.customer,
      status: event.status,
      checkoutInfo: event.checkoutInfo,
      comment: event.comment,
      serviceId: event.serviceId,
    };
    setAppointment(appointment);
    hidePopover();
    setShowDeleteConfirmation(true);
  };

  const popover = (event, hidePopover) => {
    return (
      <Popover id={`popover-${event.id}`} className="zymran-calendar-popover">
        <div className="zymran-calendar-popover__top-side">
          <div className="zymran-calendar-popover__buttons">
            <div className="zymran-calendar-popover__buttons-main">
              {event &&
                event.status &&
                event.status.name !== APPOINTMENT_STATUS.FINISHED && (
                  <>
                    <button
                      name="eventPopoverEditButton"
                      onClick={() =>
                        handleOpenEditAppointmentModal(event, hidePopover)
                      }
                      className="icon-button icon-button--sm icon-button--grey-light"
                    >
                      <ZymranIcon name="pen" />
                    </button>
                    <button
                      onClick={() =>
                        handleOpenDeleteConfirmation(event, hidePopover)
                      }
                      className="icon-button icon-button--sm icon-button--grey-light"
                    >
                      <ZymranIcon name="delete" />
                    </button>
                  </>
                )}
            </div>
            <button
              name="eventPopoverCloseButton"
              onClick={hidePopover}
              className="icon-button icon-button--sm icon-button--grey-light"
            >
              <ZymranIcon name="close" />
            </button>
          </div>
          <div className="zymran-calendar-popover__service-employee-info">
            <div className="zymran-calendar-popover__service-info">
              <div className="zymran-calendar-popover__service-name">
                <div className="calendar-popover-badge"></div>
                <h3 className="mb-0 primary-text-color">{event.title}</h3>
              </div>
              <div className="zymran-calendar-popover__service-date p2 secondary-text-color">
                {moment(event.start, "HH:mm", true).format("HH:mm")} -{" "}
                {moment(event.end, "HH:mm", true).format("HH:mm")}
              </div>
            </div>
            <div className="zymran-calendar-popover__employee-info">
              <ZymranIcon
                name="employee-2"
                size={24}
                className="calendar-popover-staff-icon"
              />
              <div className="zymran-calendar-popover__employee">
                <p className="p1 primary-text-color mb-0">{event.staffName}</p>
                <p className="p2 primary-text-color mb-0">
                  {event.staffPosition}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="zymran-calendar-popover__bottom-side">
          <div className="zymran-calendar-popover__customer-info">
            {event.customer ? (
              <>
                <ZymranAvatar
                  avatarUrl={event.customer.avatarUrl}
                  fullName={`${event.customer.firstName} ${event.customer.lastName}`}
                  size="lg"
                  shape="circle"
                />
                <div className="zymran-calendar-popover__customer-name">
                  <p className="p1 mb-0 primary-text-color">
                    {event.customer.lastName} {event.customer.firstName}
                  </p>
                  <p className="p2 mb-0 primary-text-color">
                    {event.customer.phoneNumberPrimary}
                  </p>
                  <p className="p2 mb-0 secondary-text-color">
                    {event.customerCountOfFinishedAppointments === 0 ||
                    event.customerCountOfFinishedAppointments === null ||
                    event.customerCountOfFinishedAppointments === undefined ? (
                      <span className="mb-0">
                        <Trans>first_appointment</Trans>
                      </span>
                    ) : (
                      <span className="mb-0">
                        <Trans>provided_services</Trans>:{" "}
                        {event.customerCountOfFinishedAppointments}
                      </span>
                    )}
                  </p>
                </div>
              </>
            ) : (
              <p>
                <Trans>no_customer_data_available</Trans>
              </p>
            )}
          </div>
          <div className="zymran-calendar-popover__payment">
            <p className="p1-bold mb-0 primary-text-color">
              {event?.service?.price}{" "}
              {event && event.service && event.service.price && (
                <span className="p1-bold mb-0 primary-text-color">
                  <Trans>tenge</Trans>
                </span>
              )}
            </p>
            {event && event.status.name === APPOINTMENT_STATUS.FINISHED ? (
              <Button
                name="viewAppointment"
                variant="blue"
                size="sm"
                onClick={() =>
                  handleOpenViewAppointmentModal(event, hidePopover)
                }
              >
                <Trans>view</Trans>
              </Button>
            ) : (
              <Button
                name="editAppointment"
                variant="blue"
                size="sm"
                onClick={() => handleOpenCheckoutModal(event, hidePopover)}
              >
                <Trans>pay</Trans>
              </Button>
            )}
          </div>
        </div>
      </Popover>
    );
  };

  const MyEvent = ({ event, activePopoverId, onTogglePopover }) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    const titleRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
      setShow(activePopoverId === event.id);
    }, [activePopoverId, event.id]);

    useEffect(() => {
      const titleElement = titleRef.current;
      if (titleElement) {
        setIsTitleOverflowing(
          titleElement.scrollWidth > titleElement.clientWidth
        );
      }
    }, []);

    const handleClick = (e) => {
      e.stopPropagation();
      onTogglePopover(event.id);
      setShow(!show);
    };

    const handleToggle = () => {
      setIsPopoverVisible(!isPopoverVisible);
    };

    const handleClose = () => {
      // setIsPopoverVisible(false);
      onTogglePopover(null);
    };

    return (
      <>
        <OverlayTrigger
          trigger={["click"]}
          placement="auto"
          show={show}
          overlay={popover(event, handleClose)}
          rootClose
        >
          <div ref={titleRef} onClick={handleClick} className="h-100">
            <div className="p3-bold">
              {event.title}{" "}
              {isTitleOverflowing && (
                <span>
                  {event.start.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )}
            </div>
            <div className="p4">
              <span>
                {event.start.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
            {event.customer ? (
              <div className="p4">
                {event.customer.firstName} {event.customer.lastName}
              </div>
            ) : (
              <div className="p4">
                <Trans>no_customer_data_available</Trans>
              </div>
            )}
            {/* Status label with conditional rendering and styling */}
            <div
              className={`status-label ${
                event.status.name ? event.status.name.toLowerCase() : ""
              }`}
            >
              {event.status.name === "finished" && <Trans>finished</Trans>}
              {event.status.name === "cancelled" && <Trans>cancelled</Trans>}
              {event.status.name === "new" && <Trans>to_be_paid</Trans>}
            </div>
          </div>
        </OverlayTrigger>
      </>
    );
  };

  return (
    <Card className="card-chat overflow-hidden">
      <Fragment>
        <div className="scheduler-calendar jazu-calendar scrollbar h-100">
          {/* <div className={blocking ? 'loading' : null}> */}
          {loadingCounter > 0 ? (
            <div className="d-flex flex-column h-100">
              <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center">
                <Lottie
                  animationData={loadingAnimation}
                  loop={true}
                  style={{ height: "220px", width: "220px" }}
                />
              </div>
            </div>
          ) : locations.length === 0 ? (
            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center h-100">
              <h5 className="mt-3 mb-0">
                <Trans>locations_not_added</Trans>
              </h5>
              <small>
                <Trans>please_add_locations</Trans>
              </small>
            </div>
          ) : !(
              staffSchedules &&
              Array.isArray(staffSchedules) &&
              staffSchedules.length === 0
            ) ? (
            <DragAndDropCalendar
              allDay={false}
              defaultDate={defaultDate}
              defaultView={Views.DAY}
              date={calendarStartTime}
              eventPropGetter={eventPropGetter}
              onNavigate={onNavigate}
              views={views}
              events={events}
              localizer={localizer}
              onEventDrop={handleMoveEvent}
              onEventResize={handleResizeEvent}
              onSelectSlot={handleSelectSlot}
              min={calendarStartTime}
              resizable
              resourceIdAccessor="resourceId"
              resources={resourceMap}
              resourceTitleAccessor="resourceTitle"
              scrollToTime={scrollToTime}
              selectable
              slotPropGetter={slotPropGetter}
              step={CALENDAR_STEP}
              timeslots={4}
              tooltipAccessor={null}
              components={{
                toolbar: CustomRBCToolbar,
                resourceHeader: CustomHeader,
                event: (props) => (
                  <MyEvent
                    {...props}
                    activePopoverId={activePopoverId}
                    onTogglePopover={handleTogglePopover}
                  />
                ),
              }}
            />
          ) : (
            <div className="d-flex flex-column h-100">
              <div className="rbc-toolbar row align-items-center">
                <Col xs="auto" className="d-flex justify-content-end">
                  {/*menuPortalTarget and styles property with zIndex: 9999 are needed to make dropdown appear in front of the calendar*/}
                  <Select
                    id="location"
                    name="location"
                    placeholder={t("select_location")}
                    value={selectedLocationOption}
                    options={locationOptions}
                    onChange={onChangeLocationOption}
                    ref={locationSelect}
                    menuPortalTarget={document.body}
                    classNamePrefix="react-select"
                    styles={{
                      container: (base) => ({
                        ...base,
                        zIndex: 9999,
                        minWidth: "150px",
                        flex: 1,
                      }),
                      menu: (base) => ({
                        ...base,
                        minWidth: "100%",
                      }),
                    }}
                  />
                </Col>

                <Col xs md="auto" className="d-flex justify-content-center">
                  {/* <span className="rbc-toolbar-label">{props.label}</span> */}
                </Col>
                <Col md="auto" className="d-md-none">
                  <hr />
                </Col>
                <Col md="auto" className="d-flex justify-content-center"></Col>

                {/* <span className="rbc-btn-group">{viewNamesGroup(messages)}</span> */}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 text-center">
                <img src={tumbleweed} height="50" alt="no-staff-placeholder" />
                <h5 className="mt-3 mb-0">
                  <Trans>no_staff_in_current_location</Trans>
                </h5>
                <small>
                  <Trans>add_staff_through_link</Trans>:{" "}
                  <Link to="/settings/staff">
                    <Trans>staff</Trans>
                  </Link>
                </small>
              </div>
            </div>
          )}
        </div>
      </Fragment>

      {/* Appointment create modal */}
      <Modal
        id="createAppointmentModal"
        name="createAppointmentModal"
        show={showCreateAppointmentModal}
        onHide={handleCloseCreateAppointmentModal}
        fullscreen={true}
      >
        <Modal.Header
          closeButton={false}
          closeVariant="white"
          className="fullscreen-modal-header"
        >
          <Modal.Title as="h2" className="text-white light">
            <Trans>create_appointment</Trans>
          </Modal.Title>
          <Button
            name="closeCreateAppointment"
            aria-label="Close"
            className="custom-modal-close-button"
            onClick={handleCloseCreateAppointmentModal}
          >
            <ZymranIcon
              name="close"
              size={24}
              className="custom-modal-close-icon"
            />
          </Button>
        </Modal.Header>
        <Modal.Body className="appointment-body">
          <CreateAppointment
            appointment={appointment}
            staffLocations={staffSchedules}
            calendarStartTime={calendarStartTime}
            calendarEndTime={calendarEndTime}
            calendarEndHour={CALENDAR_END_HOUR}
            timezone={timezone}
            handleAppointmentCreated={handleAppointmentCreated}
            setAppointmentsFromDB={setAppointmentsFromDB}
            handleCloseCreateAppointmentModal={
              handleCloseCreateAppointmentModal
            }
            calculateDuration={calculateDuration}
            setAppointment={setAppointment}
            selectedLocationId={selectedLocationOption.value}
            updateCheckoutInfo={updateCheckoutInfo}
          />
        </Modal.Body>
      </Modal>

      <Modal
        id="editAppointmentModal"
        name="editAppointmentModal"
        show={showEditAppointmentModal}
        onShow={handleShowEditAppointmentModal}
        onHide={handleCloseEditAppointmentModal}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="appointment-header px-card border-bottom-0"
        >
          {" "}
          <Modal.Title as="h5" className="text-white light">
            <Trans>edit_appointment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="appointment-body">
          <EditAppointment
            appointment={appointment}
            appointmentsFromDB={appointmentsFromDB}
            setAppointmentsFromDB={setAppointmentsFromDB}
            staffLocations={staffSchedules}
            calendarStartTime={calendarStartTime}
            calendarEndTime={calendarEndTime}
            calendarEndHour={CALENDAR_END_HOUR}
            timezone={timezone}
            deletedAppointments={deletedAppointments}
            setDeletedAppointments={setDeletedAppointments}
            handleCloseEditAppointmentModal={handleCloseEditAppointmentModal}
            setShowEditAppointmentModal={setShowEditAppointmentModal}
            setShowViewAppointmentModal={setShowViewAppointmentModal}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteConfirmation}
        onHide={handleCloseDeleteConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>removal</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans>sure_to_delete</Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button
            name="closeDeleteAppointment"
            variant="secondary"
            onClick={handleCloseDeleteConfirmation}
          >
            <Trans>close</Trans>
          </Button>
          <Button
            name="confirmDeletion"
            variant="primary"
            onClick={handleDeleteSingleAppointment}
          >
            <Trans>yes</Trans>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        id="viewAppointmentModal"
        name="viewAppointmentModal"
        show={showViewAppointmentModal}
        onShow={handleShowViewAppointmentModal}
        onHide={handleCloseViewAppointmentModal}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="appointment-header px-card border-bottom-0"
        >
          {" "}
          <Modal.Title as="h5" className="text-white light">
            <Trans>view_appointment</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="appointment-body">
          <ViewAppointment
            chainId={appointment.chainId}
            calendarStartTime={calendarStartTime}
            timezone={timezone}
            selectedLocationId={selectedLocationOption.value}
            updateCheckoutInfo={updateCheckoutInfo}
            handleShowEditAppointmentModalFromView={
              handleShowEditAppointmentModalFromView
            }
            handleClose={handleCloseViewAppointmentModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        id="appointmentCheckout"
        name="appointmentCheckout"
        show={showAppointmentCheckoutModal}
        onHide={() => setShowAppointmentCheckoutModal(false)}
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          closeVariant="white"
          className="appointment-header px-card border-bottom-0"
        >
          <Modal.Title as="h5" className="text-white light">
            <Trans>appointment_checkout</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCheckout
            appointment={appointment}
            locationId={selectedLocationOption.value}
            handleClose={handleCloseAppointmentCheckout}
            updateCheckoutInfo={updateCheckoutInfo}
            currentDateFormatted={currentDateFormatted}
            handleOpenViewAppointmentModal={
              handleOpenViewAppointmentModalFromCheckout
            }
            timezone={timezone}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        fullscreen={false}
        centered
        style={{ backdropFilter: "blur(8px)", zIndex: "9999" }}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>important</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="d-flex flex-column">
            <div className="mb-3">
              <Trans>staff_member_not_available_at_this_time_slot</Trans>
            </div>
            <Button
              name="confirmStaffNotAvailable"
              variant="success"
              onClick={() => setShowWarningModal(false)}
            >
              <Trans>close</Trans>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Staff Modal */}
      <Modal
        show={showEditStaffModal}
        onHide={() => setShowEditStaffModal(false)}
        fullscreen={true}
      >
        <Modal.Header closeButton closeVariant="white" className="bg-primary">
          <Modal.Title className="text-white light">
            <Trans>edit</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <EditStaff
            staffId={selectedStaffId}
            onUpdate={handleStaffUpdate}
            handleClose={() => setShowEditStaffModal(false)}
          />
        </Modal.Body>
      </Modal>
      <BlockUI tag="div" blocking={blocking} />
    </Card>
  );
};

export default Scheduler;
