import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import { Typewriter } from 'react-simple-typewriter';
import dashboard from "assets/img/UI.png";
import Section from "component/common/Section";
import AppContext from "context/Context";
import heroBg from "assets/img/hero-bg.png";

const Hero = () => {
  const {
    config: { },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isKazakh = currentLanguage === 'kz';
  const typewriterWords = t('hero_section_typewriter_words', { returnObjects: true });

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={heroBg}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white fw-light hero-section-title">
            <Trans>hero_section_title_start</Trans>{' '}
            <br />
            <span className="fw-bold">
              <Typewriter
                words={typewriterWords}
                loop={false}
                cursor={true}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            {isKazakh ? t('hero_section_title_end') : ''}
          </h1>
          <p className="lead text-white opacity-75">
            <Trans>hero_section_description</Trans>
          </p>
          <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="#!"
          >
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
