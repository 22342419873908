import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ZymranAvatar from "component/common/ZymranAvatar";
import { ReactComponent as DefaultAvatar } from "assets/img/zymran-default-avatar.svg";
import { signOut } from "service/auth/AuthService";
import { Trans } from "react-i18next";
import Cookies from "js-cookie";

const ProfileDropdown = () => {

  const currentUser = Cookies.get("zymran_user") ? JSON.parse(Cookies.get("zymran_user")) : null;

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="p-0 nav-link profile-dropdown-link"
      >
        <div className="avatar">
          {currentUser ?
            <ZymranAvatar
              avatarUrl={currentUser.avatarUrl}
              fullName={currentUser.fullName}
              size="md"
              shape="circle"
              defaultAvatarIcon={DefaultAvatar}
            /> : <></>}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end p-0">
        <div className="bg-white rounded-lg p-2 dark__bg-1000 w-100">
          <Dropdown.Item as={Link} to="/profile">
            <Trans>profile</Trans>
          </Dropdown.Item>
          <Dropdown.Item as={Link} reloadDocument onClick={signOut} to="/">
            <Trans>sign_out</Trans>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
