import ZymranAvatar from "component/common/ZymranAvatar";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
  getCountOfFinishedAppointments,
  getSumOfFinishedAppointments,
} from "service/customer/CustomerService";

const CustomerInfoForCalendar = ({ customerData }) => {
  const [countOfFinishedAppointments, setCountOfFinishedAppointments] =
    useState(0);
  const [sumOfFinishedAppointments, setSumOfFinishedAppointments] = useState(0);
  const countOfCanceledAppointments = 0;

  useEffect(() => {
    loadNumberOfServicesProvided();
    loadSumOfServicesProvided();
    // eslint-disable-next-line
  }, []);

  const loadNumberOfServicesProvided = async () => {
    try {
      const response = customerData?.id
        ? await getCountOfFinishedAppointments(customerData.id)
        : { data: 0 };
      setCountOfFinishedAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSumOfServicesProvided = async () => {
    try {
      const response = customerData?.id
        ? await getSumOfFinishedAppointments(customerData.id)
        : { data: 0 };
      setSumOfFinishedAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="customer-pane__avatar-info">
        <ZymranAvatar
          avatarUrl={customerData.avatarUrl}
          fullName={`${customerData.firstName} ${customerData.lastName}`}
          size="xl"
          shape="circle"
        />
        <div className="customer-pane__info">
          <div className="p1-bold primary-text-color">
            {customerData.firstName} {customerData.lastName}
          </div>
          <a href={`mailto:`} className="p2">
            {customerData.email}
          </a>
          <p className="p2 secondary-text-color mb-0">
            {customerData.phoneNumberPrimary}
          </p>
          <p className="p2 secondary-text-color mb-0">
            <Trans>birth_date</Trans>:{" "}
            {customerData.dob
              ? moment(customerData.dob).format("DD.MM.YYYY")
              : ""}
          </p>
        </div>
      </div>
      <hr className="customer-pane__divider" />
      <div className="customer-pane__stats">
        <div className="customer-pane__info-box">
          <div className="p1-bold text-center primary-text-color">
            {countOfFinishedAppointments}
          </div>
          <div className="p3 text-center secondary-text-color">
            <Trans>number_of_provided_services</Trans>
          </div>
        </div>
        <div className="customer-pane__info-box">
          <div className="p1-bold text-center primary-text-color">
            {sumOfFinishedAppointments}
          </div>
          <div className="p3 text-center secondary-text-color">
            <Trans>sum_of_provided_services</Trans>
          </div>
        </div>
        <div className="customer-pane__info-box">
          <div className="p1-bold text-center primary-text-color">
            {countOfCanceledAppointments}
          </div>
          <div className="p3 text-center secondary-text-color">
            <Trans>number_of_cancelled_appointments</Trans>
          </div>
        </div>
      </div>
      <p className="p2 secondary-text-color text-center w-100 mb-0">
        <Trans>client_since</Trans>{" "}
        {moment(customerData.createdAt).format("DD.MM.YYYY")}
      </p>
      {/* <hr className="customer-pane__divider" />
      <div className="customer-pane__comments">
        <div className="p1 primary-text-color">
          <Trans>comments</Trans>
        </div>
        <div className="customer-pane__comments__item">
          <div className="p2 secondary-text-color">Employee Name:</div>
          <div className="p2 primary-text-color">Comment here</div>
        </div>
      </div> */}
    </>
  );
};

export default CustomerInfoForCalendar;
