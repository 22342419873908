import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import FalconDropzone from "component/common/FalconDropzone";
import {
  getUserProfile,
  updateUserProfile,
} from "service/profile/UserProfileService";
import { notifySuccess } from "util/notify";
import EnhancedFormControl from "component/common/EnhancedFormControl";
import Flex from "component/common/Flex";
import { uploadAvatar } from "service/profile/UserProfileService";
import instagramIcon from "assets/img/icons/socials/inst16.png";
import facebookIcon from "assets/img/icons/socials/fb16.png";
import telegramIcon from "assets/img/icons/socials/tg16.png";
import vkIcon from "assets/img/icons/socials/vk16.png";
import profileAvatarDropzone from "assets/img/profile-avatar-dropzone.png";
import Cookies from "js-cookie";

const EditProfile = ({ handleClose }) => {
  const [avatar, setAvatar] = useState([]);

  const [profileData, setProfileData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dob: "",
    notes: "",
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const currentUser = Cookies.get("zymran_user")
    ? JSON.parse(Cookies.get("zymran_user"))
    : null;

  useEffect(() => {
    if (currentUser) {
      getProfile();
    }
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    try {
      const response = await getUserProfile(currentUser.login);

      let data = {
        email: response.data.email,
        phoneNumber: response.data.phoneNumber,
        companyName: response.data.companyName,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
      };

      if (!data.phoneNumber) {
        data.phoneNumber = "";
      }

      if (response.data.avatarUrl) {
        setAvatar([
          {
            url: response.data.avatarUrl,
          },
        ]);
      }
      setProfileData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const setField = (field, value) => {
    setProfileData({
      ...profileData,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const sendFileToBackend = async (base64EncodedImage, mimeType) => {
    try {
      const response = await uploadAvatar(base64EncodedImage, mimeType);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    const payload = {
      companyName: profileData.companyName,
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      email: profileData.email,
      phoneNumber: profileData.phoneNumber,
    };
    if (avatar.length > 0 && avatar[0].base64) {
      const fileData = avatar[0];
      try {
        // Upload the avatar first
        const avatarResponse = await sendFileToBackend(
          fileData.base64,
          fileData.type
        );
        payload.avatarFilename = avatarResponse.data.filename;
        payload.avatarUrl = avatarResponse.data.url;
      } catch (error) {
        console.error(error);
      }
    } else if (avatar.length > 0 && avatar[0].url) {
      // payload.avatarFilename = response.data.avatarFilename;
      payload.avatarUrl = avatar[0].url;
    }

    console.log("payload", payload);

    try {
      await updateUserProfile(currentUser.login, payload);
      notifySuccess(t("values_updated"));
      // dispatch(updateUser(payload));
    } catch (error) {
      console.error(error);
    } finally {
      // setBlocking(false);
    }
    handleClose();
  };

  const handleFileChange = (uploadedFiles) => {
    setAvatar(uploadedFiles);
  };

  return (
    <div className="container central-content-container">
      <div className="d-flex align-items-stretch gap-3 mb-3">
        <Card className="edit-profile-card profile-basic-info-card">
          <Card.Header>
            <h3>
              <Trans>basic_information</Trans>
            </h3>
          </Card.Header>
          <Card.Body>
            <FalconDropzone
              files={avatar}
              name="avatar-dropzone"
              className="edit-profile-avatar-dropzone"
              onChange={handleFileChange}
              multiple={false}
              accept="image/*"
              preview={true}
              isCircular={true}
              placeholder={
                <>
                  <Flex justifyContent="center" className="position-relative">
                    <img
                      src={profileAvatarDropzone}
                      alt=""
                      width={96}
                      height={96}
                    />
                  </Flex>
                </>
              }
            />

            <div className="edit-profile-inputs-block">
              <EnhancedFormControl
                id="companyName"
                name="companyName"
                type="text"
                label={t("company_name")}
                value={profileData.companyName || ""}
                onChange={(e) => setField("companyName", e.target.value)}
                isInvalid={!!errors.companyName}
                placeholder={t("company_name")}
                errors={errors.companyName}
                groupClassName="w-100"
              />
              <EnhancedFormControl
                id="firstName"
                name="firstName"
                type="text"
                label={t("firstname")}
                value={profileData.firstName || ""}
                onChange={(e) => setField("firstName", e.target.value)}
                isInvalid={!!errors.firstName}
                placeholder={t("firstname")}
                errors={errors.firstName}
                groupClassName="w-100"
              />
              <EnhancedFormControl
                id="lastName"
                name="lastName"
                type="text"
                label={t("lastname")}
                value={profileData.lastName || ""}
                onChange={(e) => setField("lastName", e.target.value)}
                isInvalid={!!errors.lastName}
                placeholder={t("lastname")}
                errors={errors.lastName}
                groupClassName="w-100"
              />
              <EnhancedFormControl
                id="email"
                name="email"
                type="text"
                label={t("email")}
                value={profileData.email}
                onChange={(e) => setField("email", e.target.value)}
                isInvalid={!!errors.email}
                placeholder={t("email")}
                errors={errors.email}
                groupClassName="w-100"
                disabled={true}
              />
              <EnhancedFormControl
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                label={t("phone_number")}
                value={profileData.phoneNumber}
                onChange={(e) => setField("phoneNumber", e.target.value)}
                isInvalid={!!errors.phoneNumber}
                placeholder={t("phone_number")}
                errors={errors.email}
                groupClassName="w-100"
                disabled={true}
              />
            </div>
          </Card.Body>
        </Card>
        <Card className="edit-profile-card align-self-stretch disabled-element">
          <Card.Header>
            <h3>
              <Trans>social_networks</Trans>
            </h3>
          </Card.Header>
          <Card.Body>
            <div className="d-flex flex-column py-2 align-items-start gap-2">
              <div className="d-flex align-items-center gap-2">
                <img src={instagramIcon} alt="instagram-icon" />
                <span className="p1">Instagram</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={facebookIcon} alt="facebook-icon" />
                <span className="p1">Facebook</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={telegramIcon} alt="telegram-icon" />
                <span className="p1">Telegram</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={vkIcon} alt="vkontakte-icon" />
                <span className="p1">Vkontakte</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-flex flex-row justify-content-end">
        <Button onClick={handleSubmit}>
          <Trans>save</Trans>
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;
